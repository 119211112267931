body {
    overflow-x: hidden;
}

.sumir {
    color: red;
}

.vocedevolta {
    font-family: "Archivo", serif;
    font-optical-sizing: auto;
    font-size: 40px;
    font-weight: 400;
    font-style: normal;
    color: #9095A1;
}

input[type=text] {
    outline: none;
  }
  
  input[type=text]:focus {
    border: none;
  }
.quadradologin {
    width: 471px;
    height: 443px;
    border: 2px #5E3F3C solid;
    border-radius: 6px;
}

.botaoLogin {
    background-color: red; /* For browsers that do not support gradients */
    background-image: linear-gradient(to right, #62309F, #81379A , #A63C96, #DA4891, #FE6885, #FE8477, #FFA767);
    display: flex; 
    justify-content: center;
    border-radius: 6px;
    border: none;
    color: #fff;
    width: 100%;
    padding: 10px 0px;

    font-family: "Inter", serif;
    font-weight: 300;
    font-size: 16px;



}

.seespaco {
    font-family: "Inter", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    text-decoration: none;
    color: #9095A1;
}
 
.fonteLogin {
    font-family: "Archivo", serif;
    font-size: 32px;
    font-weight: 700;
}

.fontLoginInput {
    font-family: "Inter", serif;
    font-weight: bold;
    font-size: 14px;
    color: #424856;
}

.fontLoginInputPlace::-webkit-input-placeholder {
    font-family: "Inter", serif;
    font-weight: 300;
    font-size: 14px;
    color: #BDC1CA;
    
}

.fontLoginFoget {
    font-family: "Inter", serif;
    font-weight: 400;
    font-size: 14px;
    color: #171A1F;
}

.loginEsqueciSenha {
    font-family: "Inter", serif;
    font-size: 12px;
    color: #62309F;
    text-decoration: none;
}



@media (max-width: 700px) {
    
.sumir { display: none; }
.vocedevolta {
    align-items: center;
}

.cardlogin {
    align-items: center;
    margin: 0px;
} 

}

