


.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 0;
  --bs-card-border-color: rgba(0, 0, 0, 0.125);
  --bs-card-border-radius: 0.75rem;
  --bs-card-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --bs-card-inner-border-radius: 0.75rem;
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: #fff;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}

.card>hr {
  margin-right: 0;
  margin-left: 0;
}

.card>.list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card>.list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card>.list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link+.card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}



.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 16 16'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 16 16'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg)
      /* rtl:ignore */
    ;
  }
}

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {

  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.offcanvas-sm,
.offcanvas-md,
.offcanvas-lg,
.offcanvas-xl,
.offcanvas-xxl,
.offcanvas {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: rgba(0, 0, 0, 0.2);
  --bs-offcanvas-box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.12);
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-sm.showing,
  .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-sm.showing,
  .offcanvas-sm.hiding,
  .offcanvas-sm.show {
    visibility: visible;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-md.showing,
  .offcanvas-md.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-md.showing,
  .offcanvas-md.hiding,
  .offcanvas-md.show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-md .offcanvas-header {
    display: none;
  }

  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-lg.showing,
  .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-lg.showing,
  .offcanvas-lg.hiding,
  .offcanvas-lg.show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-lg .offcanvas-header {
    display: none;
  }

  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-xl.showing,
  .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xl.showing,
  .offcanvas-xl.hiding,
  .offcanvas-xl.show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-xl .offcanvas-header {
    display: none;
  }

  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-xxl.showing,
  .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xxl.showing,
  .offcanvas-xxl.hiding,
  .offcanvas-xxl.show {
    visibility: visible;
  }
}

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-xxl .offcanvas-header {
    display: none;
  }

  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}

.offcanvas.showing,
.offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing,
.offcanvas.hiding,
.offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}

.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}

.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #000 !important;
  background-color: RGBA(233, 30, 99, var(--bs-bg-opacity, 1)) !important;
}


.btn-outline-secondary {
  box-shadow: none;
}

.btn-outline-secondary:hover:not(.active) {
  background-color: transparent;
  opacity: .75;
  box-shadow: none;
  color: #7b809a;
}

.btn-success,
.btn.bg-gradient-success {
  box-shadow: 0 3px 3px 0 rgba(76, 175, 80, 0.15), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.15);
}

.btn-success:hover,
.btn.bg-gradient-success:hover {
  background-color: #4CAF50;
  border-color: #4CAF50;
  box-shadow: 0 14px 26px -12px rgba(76, 175, 80, 0.4), 0 4px 23px 0 rgba(76, 175, 80, 0.15), 0 8px 10px -5px rgba(76, 175, 80, 0.2);
}

.btn-success .btn.bg-outline-success,
.btn.bg-gradient-success .btn.bg-outline-success {
  border: 1px solid #4CAF50;
}



.btn-success.focus,
.btn-success:focus,
.btn.bg-gradient-success.focus,
.btn.bg-gradient-success:focus {
  color: #fff;
}

.btn-outline-success {
  box-shadow: none;
}

.btn-outline-success:hover:not(.active) {
  background-color: transparent;
  opacity: .75;
  box-shadow: none;
  color: #4CAF50;
}

.btn-info,
.btn.bg-gradient-info {
  box-shadow: 0 3px 3px 0 rgba(26, 115, 232, 0.15), 0 3px 1px -2px rgba(26, 115, 232, 0.2), 0 1px 5px 0 rgba(26, 115, 232, 0.15);
}

.btn-info:hover,
.btn.bg-gradient-info:hover {
  background-color: #1A73E8;
  border-color: #1A73E8;
  box-shadow: 0 14px 26px -12px rgba(26, 115, 232, 0.4), 0 4px 23px 0 rgba(26, 115, 232, 0.15), 0 8px 10px -5px rgba(26, 115, 232, 0.2);
}

.btn-info .btn.bg-outline-info,
.btn.bg-gradient-info .btn.bg-outline-info {
  border: 1px solid #1A73E8;
}



.btn-info.focus,
.btn-info:focus,
.btn.bg-gradient-info.focus,
.btn.bg-gradient-info:focus {
  color: #fff;
}

.btn-outline-info {
  box-shadow: none;
}

.btn-outline-info:hover:not(.active) {
  background-color: transparent;
  opacity: .75;
  box-shadow: none;
  color: #1A73E8;
}

.btn-warning,
.btn.bg-gradient-warning {
  box-shadow: 0 3px 3px 0 rgba(251, 140, 0, 0.15), 0 3px 1px -2px rgba(251, 140, 0, 0.2), 0 1px 5px 0 rgba(251, 140, 0, 0.15);
}

.btn-warning:hover,
.btn.bg-gradient-warning:hover {
  background-color: #fb8c00;
  border-color: #fb8c00;
  box-shadow: 0 14px 26px -12px rgba(251, 140, 0, 0.4), 0 4px 23px 0 rgba(251, 140, 0, 0.15), 0 8px 10px -5px rgba(251, 140, 0, 0.2);
}

.btn-warning .btn.bg-outline-warning,
.btn.bg-gradient-warning .btn.bg-outline-warning {
  border: 1px solid #fb8c00;
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,


.btn-warning.focus,
.btn-warning:focus,
.btn.bg-gradient-warning.focus,
.btn.bg-gradient-warning:focus {
  color: #fff;
}

.btn-outline-warning {
  box-shadow: none;
}

.btn-outline-warning:hover:not(.active) {
  background-color: transparent;
  opacity: .75;
  box-shadow: none;
  color: #fb8c00;
}

.btn-danger,
.btn.bg-gradient-danger {
  box-shadow: 0 3px 3px 0 rgba(244, 67, 53, 0.15), 0 3px 1px -2px rgba(244, 67, 53, 0.2), 0 1px 5px 0 rgba(244, 67, 53, 0.15);
}

.btn-danger:hover,
.btn.bg-gradient-danger:hover {
  background-color: #F44335;
  border-color: #F44335;
  box-shadow: 0 14px 26px -12px rgba(244, 67, 53, 0.4), 0 4px 23px 0 rgba(244, 67, 53, 0.15), 0 8px 10px -5px rgba(244, 67, 53, 0.2);
}

.btn-danger .btn.bg-outline-danger,
.btn.bg-gradient-danger .btn.bg-outline-danger {
  border: 1px solid #F44335;
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,

.btn-danger.focus,
.btn-danger:focus,
.btn.bg-gradient-danger.focus,
.btn.bg-gradient-danger:focus {
  color: #fff;
}

.btn-outline-danger {
  box-shadow: none;
}

.btn-outline-danger:hover:not(.active) {
  background-color: transparent;
  opacity: .75;
  box-shadow: none;
  color: #F44335;
}

.btn-light,
.btn.bg-gradient-light {
  box-shadow: 0 3px 3px 0 rgba(240, 242, 245, 0.15), 0 3px 1px -2px rgba(240, 242, 245, 0.2), 0 1px 5px 0 rgba(240, 242, 245, 0.15);
}

.btn-light:hover,
.btn.bg-gradient-light:hover {
  background-color: #f0f2f5;
  border-color: #f0f2f5;
  box-shadow: 0 14px 26px -12px rgba(240, 242, 245, 0.4), 0 4px 23px 0 rgba(240, 242, 245, 0.15), 0 8px 10px -5px rgba(240, 242, 245, 0.2);
}

.btn-light .btn.bg-outline-light,
.btn.bg-gradient-light .btn.bg-outline-light {
  border: 1px solid #f0f2f5;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,

.btn-outline-light {
  box-shadow: none;
}

.btn-outline-light:hover:not(.active) {
  background-color: transparent;
  opacity: .75;
  box-shadow: none;
  color: #f0f2f5;
}

.btn-dark,
.btn.bg-gradient-dark {
  box-shadow: 0 3px 3px 0 rgba(52, 71, 103, 0.15), 0 3px 1px -2px rgba(52, 71, 103, 0.2), 0 1px 5px 0 rgba(52, 71, 103, 0.15);
}

.btn-dark:hover,
.btn.bg-gradient-dark:hover {
  background-color: #344767;
  border-color: #344767;
  box-shadow: 0 14px 26px -12px rgba(52, 71, 103, 0.4), 0 4px 23px 0 rgba(52, 71, 103, 0.15), 0 8px 10px -5px rgba(52, 71, 103, 0.2);
}

.btn-dark .btn.bg-outline-dark,
.btn.bg-gradient-dark .btn.bg-outline-dark {
  border: 1px solid #344767;
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,

.btn-dark.focus,
.btn-dark:focus,
.btn.bg-gradient-dark.focus,
.btn.bg-gradient-dark:focus {
  color: #fff;
}

.btn-outline-dark {
  box-shadow: none;
}

.btn-outline-dark:hover:not(.active) {
  background-color: transparent;
  opacity: .75;
  box-shadow: none;
  color: #344767;
}

.btn-white,
.btn.bg-gradient-white {
  box-shadow: 0 3px 3px 0 rgba(255, 255, 255, 0.15), 0 3px 1px -2px rgba(255, 255, 255, 0.2), 0 1px 5px 0 rgba(255, 255, 255, 0.15);
}

.btn-white:hover,
.btn.bg-gradient-white:hover {
  background-color: #fff;
  border-color: #fff;
  box-shadow: 0 14px 26px -12px rgba(255, 255, 255, 0.4), 0 4px 23px 0 rgba(255, 255, 255, 0.15), 0 8px 10px -5px rgba(255, 255, 255, 0.2);
}

.btn-white .btn.bg-outline-white,
.btn.bg-gradient-white .btn.bg-outline-white {
  border: 1px solid #fff;
}

.btn-white:not(:disabled):not(.disabled).active,
.btn-white:not(:disabled):not(.disabled):active,


.btn-outline-white {
  box-shadow: none;
}

.btn-outline-white:hover:not(.active) {
  background-color: transparent;
  opacity: .75;
  box-shadow: none;
  color: #fff;
}

.btn-dark-blue,
.btn.bg-gradient-dark-blue {
  box-shadow: 0 3px 3px 0 rgba(26, 35, 126, 0.15), 0 3px 1px -2px rgba(26, 35, 126, 0.2), 0 1px 5px 0 rgba(26, 35, 126, 0.15);
}

.btn-dark-blue:hover,
.btn.bg-gradient-dark-blue:hover {
  background-color: #1A237E;
  border-color: #1A237E;
  box-shadow: 0 14px 26px -12px rgba(26, 35, 126, 0.4), 0 4px 23px 0 rgba(26, 35, 126, 0.15), 0 8px 10px -5px rgba(26, 35, 126, 0.2);
}

.btn-dark-blue .btn.bg-outline-dark-blue,
.btn.bg-gradient-dark-blue .btn.bg-outline-dark-blue {
  border: 1px solid #1A237E;
}

.btn-dark-blue:not(:disabled):not(.disabled).active,
.btn-dark-blue:not(:disabled):not(.disabled):active,


.btn-dark-blue.focus,
.btn-dark-blue:focus,
.btn.bg-gradient-dark-blue.focus,
.btn.bg-gradient-dark-blue:focus {
  color: #fff;
}

.btn-outline-dark-blue {
  box-shadow: none;
}

.btn-outline-dark-blue:hover:not(.active) {
  background-color: transparent;
  opacity: .75;
  box-shadow: none;
  color: #1A237E;
}

.btn-outline-white {
  border-color: rgba(255, 255, 255, 0.75);
  background: rgba(255, 255, 255, 0.1);
}

.btn-primary,
.btn.bg-gradient-primary {
  color: #fff;
}

.btn-primary:hover,
.btn.bg-gradient-primary:hover {
  color: #fff;
}

.btn-secondary,
.btn.bg-gradient-secondary {
  color: #fff;
}

.btn-secondary:hover,
.btn.bg-gradient-secondary:hover {
  color: #fff;
}

.btn-danger,
.btn.bg-gradient-danger {
  color: #fff;
}

.btn-danger:hover,
.btn.bg-gradient-danger:hover {
  color: #fff;
}

.btn-info,
.btn.bg-gradient-info {
  color: #fff;
}

.btn-info:hover,
.btn.bg-gradient-info:hover {
  color: #fff;
}

.btn-success,
.btn.bg-gradient-success {
  color: #fff;
}

.btn-success:hover,
.btn.bg-gradient-success:hover {
  color: #fff;
}

.btn-warning,
.btn.bg-gradient-warning {
  color: #fff;
}

.btn-warning:hover,
.btn.bg-gradient-warning:hover {
  color: #fff;
}

.btn-dark,
.btn.bg-gradient-dark {
  color: #fff;
}

.btn-dark:hover,
.btn.bg-gradient-dark:hover {
  color: #fff;
}

.btn-light,
.btn.bg-gradient-light {
  color: #3A416F;
}

.btn-light:hover,
.btn.bg-gradient-light:hover {
  color: #3A416F;
}

.breadcrumb-item {
  font-size: 0.875rem;
}

.breadcrumb-item.text-white::before {
  color: #fff;
}

.breadcrumb-dark {
  background-color: #344767;
}

.breadcrumb-dark .breadcrumb-item {
  font-weight: 600;
}

.breadcrumb-dark .breadcrumb-item a {
  color: #f8f9fa;
}

.breadcrumb-dark .breadcrumb-item a:hover {
  color: #fff;
}

.breadcrumb-dark .breadcrumb-item+.breadcrumb-item::before {
  color: #adb5bd;
}

.breadcrumb-dark .breadcrumb-item.active {
  color: #dee2e6;
}

.breadcrumb-links {
  padding: 0;
  margin: 0;
  background: transparent;
}

.card {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.card[data-animation="true"] .card-header {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
  -moz-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
  -o-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
  -ms-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
  transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
}

.card:hover[data-animation="true"] .card-header {
  -webkit-transform: translate3d(0, -50px, 0);
  -moz-transform: translate3d(0, -50px, 0);
  -o-transform: translate3d(0, -50px, 0);
  -ms-transform: translate3d(0, -50px, 0);
  transform: translate3d(0, -50px, 0);
}

.card .card-header {
  padding: 1.5rem;
}

.card .card-body {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  padding: 1.5rem;
}

.card.card-plain {
  background-color: transparent;
  box-shadow: none;
}

.card .card-footer {
  padding: 1.5rem;
  background-color: transparent;
}

.author {
  display: flex;
}

.author .name>span {
  line-height: 1.571;
  font-weight: 600;
  font-size: 0.875rem;
  color: #3A416F;
}

.author .stats {
  font-size: 0.875rem;
  font-weight: 400;
}

.card.card-background {
  align-items: center;
}

.card.card-background .full-background {
  background-position: 50%;
  background-size: cover;
  margin-bottom: 30px;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 0.75rem;
}

.card.card-background .card-body {
  color: #fff;
  position: relative;
  z-index: 2;
}

.card.card-background .card-body .content-center,
.card.card-background .card-body .content-left {
  min-height: 330px;
  max-width: 450px;
  padding-top: 60px;
  padding-bottom: 60px;
}

.card.card-background .card-body .content-center {
  text-align: center;
}

.card.card-background .card-body.body-left {
  width: 90%;
}

.card.card-background .card-body .author .name span,
.card.card-background .card-body .author .name .stats {
  color: #fff;
}

.card.card-background:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: block;
  content: "";
  background: rgba(0, 0, 0, 0.56);
  border-radius: 0.75rem;
}

.card.card-background.card-background-mask-primary:before {
  background: rgba(0, 0, 0, 0.2);
}

.card.card-background.card-background-mask-primary:after {
  background-image: linear-gradient(195deg, #EC407A 0%, #D81B60 100%);
  opacity: .85;
}

.card.card-background.card-background-mask-secondary:before {
  background: rgba(0, 0, 0, 0.2);
}

.card.card-background.card-background-mask-secondary:after {
  background-image: linear-gradient(195deg, #747b8a 0%, #495361 100%);
  opacity: .85;
}

.card.card-background.card-background-mask-success:before {
  background: rgba(0, 0, 0, 0.2);
}

.card.card-background.card-background-mask-success:after {
  background-image: linear-gradient(195deg, #66BB6A 0%, #43A047 100%);
  opacity: .85;
}

.card.card-background.card-background-mask-info:before {
  background: rgba(0, 0, 0, 0.2);
}

.card.card-background.card-background-mask-info:after {
  background-image: linear-gradient(195deg, #49a3f1 0%, #1A73E8 100%);
  opacity: .85;
}

.card.card-background.card-background-mask-warning:before {
  background: rgba(0, 0, 0, 0.2);
}

.card.card-background.card-background-mask-warning:after {
  background-image: linear-gradient(195deg, #FFA726 0%, #FB8C00 100%);
  opacity: .85;
}

.card.card-background.card-background-mask-danger:before {
  background: rgba(0, 0, 0, 0.2);
}

.card.card-background.card-background-mask-danger:after {
  background-image: linear-gradient(195deg, #EF5350 0%, #E53935 100%);
  opacity: .85;
}

.card.card-background.card-background-mask-light:before {
  background: rgba(0, 0, 0, 0.2);
}

.card.card-background.card-background-mask-light:after {
  background-image: linear-gradient(195deg, #EBEFF4 0%, #CED4DA 100%);
  opacity: .85;
}

.card.card-background.card-background-mask-dark:before {
  background: rgba(0, 0, 0, 0.2);
}

.card.card-background.card-background-mask-dark:after {
  background-image: linear-gradient(195deg, #42424a 0%, #191919 100%);
  opacity: .85;
}

.card.card-background .card-category {
  font-size: 0.875rem;
  font-weight: 600;
}

.card.card-background .card-description {
  margin-top: 24px;
  margin-bottom: 24px;
}

.rotating-card-container {
  -webkit-perspective: 800px;
  -moz-perspective: 800px;
  -o-perspective: 800px;
  -ms-perspective: 800px;
  perspective: 800px;
}

.rotating-card-container .card-rotate {
  background: transparent;
  box-shadow: none;
}

.rotating-card-container .card-rotate:after {
  display: none;
}

.rotating-card-container .card {
  -webkit-transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
  -moz-transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
  -o-transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
  -ms-transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
  transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: relative;
}

.rotating-card-container .card .back,
.rotating-card-container .card .front {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  background-color: #fff;
  border-radius: 0.5rem;
  top: 0;
  left: 0;
  justify-content: center;
  align-content: center;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -o-flex;
  display: flex;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
}

.rotating-card-container .card .back .card-body,
.rotating-card-container .card .front .card-body {
  justify-content: center;
  align-content: center;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -o-flex;
  display: flex;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
}

.rotating-card-container .card .back:after,
.rotating-card-container .card .front:after {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  content: "";
  border-radius: 0.5rem;
  background-image: linear-gradient(195deg, #EC407A, #D81B60);
  opacity: .85;
}

.rotating-card-container .card .front {
  z-index: 2;
  position: relative;
}

.rotating-card-container .card .back {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
  z-index: 5;
  text-align: center;
  width: 100%;
  height: 100%;
}

.rotating-card-container .card .back.back-background .card-body {
  position: relative;
  z-index: 2;
}

.rotating-card-container .card .back .card-footer .btn {
  margin: 0;
}

.rotating-card-container .card .back .card-body {
  padding-left: 15px;
  padding-right: 15px;
}

.rotating-card-container:not(.manual-flip):hover .card {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.rotating-card-container.hover.manual-flip .card {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.card-profile .rotating-card-container .front {
  text-align: left;
}

.back-background .card-body {
  min-height: auto;
  padding-top: 15px;
  padding-bottom: 15px;
}

/*       Fix bug for IE      */
@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {

  .rotating-card-container .card .back,
  .rotating-card-container .card .front {
    -ms-backface-visibility: visible;
    backface-visibility: visible;
  }

  .rotating-card-container .card .back {
    visibility: hidden;
    transition: visibility 0.3s cubic-bezier(0.34, 1.45, 0.7, 1);
  }

  .rotating-card-container .card .front {
    z-index: 4;
  }

  .rotating-card-container.manual-flip.hover .card .back,
  .rotating-card-container:not(.manual-flip):hover .card .back {
    z-index: 5;
    visibility: visible;
  }
}

.dark-version {
  background-color: #1a2035 !important;
}

.dark-version .main-content {
  background-color: #1a2035 !important;
}

.dark-version.virtual-reality>div {
  background-image: none !important;
}

.dark-version .sidenav {
  background: #1f283e !important;
}

.dark-version .sidenav.bg-transparent {
  background: transparent !important;
}

.dark-version .sidenav.bg-transparent .navbar-nav .nav-link {
  color: #fff !important;
}

.dark-version .sidenav.bg-transparent .nav .nav-link {
  color: #fff !important;
}

.dark-version .sidenav.bg-white {
  background: #fff !important;
}

.dark-version .sidenav.bg-white .navbar-nav .nav-link.active:after {
  color: rgba(206, 212, 218, 0.7);
}

.dark-version .sidenav.bg-white .collapse .nav-item .nav-link:not(.active) i {
  color: #344767 !important;
}

.dark-version .sidenav.bg-white .collapse .nav-item h6,
.dark-version .sidenav.bg-white .collapse .nav-item .h6 {
  color: #344767 !important;
}

.dark-version .sidenav .collapse .nav-item .nav-link i {
  color: #fff !important;
}

.dark-version .sidenav.bg-white .collapse .nav-item .nav-link.active.text-dark i,
.dark-version .sidenav.bg-white .collapse .nav-item .nav-link.active.text-dark .nav-link-text {
  color: #344767 !important;
}

.dark-version .fixed-plugin .btn.bg-gradient-dark,
.dark-version .fixed-plugin .btn.btn-outline-dark {
  color: #fff !important;
  border: 1px solid #fff !important;
}

.dark-version .fixed-plugin .btn.active {
  background: #fff !important;
  color: #344767 !important;
}

.dark-version .bg-gradient-dark {
  background-image: linear-gradient(195deg, #323a54, #1a2035) !important;
}





.page-header {
  padding: 0;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: 50%;
}

.page-header .container {
  z-index: 1;
}

.page-header video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  transform: translateX(-50%) translateY(-50%);
}

.fixed-plugin .fixed-plugin-button {
  background: #fff;
  border-radius: 50%;
  bottom: 30px;
  right: 30px;
  font-size: 1.25rem;
  z-index: 990;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
}

.fixed-plugin .fixed-plugin-button i {
  pointer-events: none;
}

.fixed-plugin .card {
  position: fixed !important;
  right: -360px;
  top: 0;
  height: 100%;
  left: auto !important;
  transform: unset !important;
  width: 360px;
  border-radius: 0;
  padding: 0 10px;
  transition: .2s ease;
  z-index: 1020;
}

.fixed-plugin .badge {
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 23px;
  margin-right: 5px;
  position: relative;
  width: 23px;
  transition: all 0.2s ease-in-out;
}

.fixed-plugin .badge:hover,
.fixed-plugin .badge.active {
  border-color: #344767;
}

.fixed-plugin .btn.bg-gradient-dark:not(:disabled):not(.disabled) {
  border: 1px solid transparent;
}

.fixed-plugin .btn.bg-gradient-dark:not(:disabled):not(.disabled):not(.active) {
  background-color: transparent;
  background-image: none;
  border: 1px solid #344767;
  color: #344767;
}

.fixed-plugin.show .card {
  right: 0;
}

.input-group {
  border-radius: 0;
}

.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.input-group.has-validation> :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation>.dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.input-group,
.input-group .input-group-text {
  transition: 0.2s ease;
  border: none;
}

.input-group> :not(:first-child):not(.dropdown-menu) {
  margin-left: 2px;
}

.input-group label {
  transition: all 0.3s ease;
}

.input-group.input-group-dynamic .form-control,
.input-group.input-group-static .form-control {
  background: no-repeat bottom, 50% calc(100% - 1px);
  background-size: 0 100%, 100% 100%;
  transition: 0.2s ease;
}

.input-group.input-group-dynamic .form-control:not(:first-child),
.input-group.input-group-static .form-control:not(:first-child) {
  border-left: 0;
  padding-left: 0;
}

.input-group.input-group-dynamic .form-control:not(:last-child),
.input-group.input-group-static .form-control:not(:last-child) {
  border-right: 0;
  padding-right: 0;
}

.input-group.input-group-dynamic .form-control+.input-group-text,
.input-group.input-group-static .form-control+.input-group-text {
  border-left: 0;
  border-right: 1px solid #d2d6da;
}

.input-group.input-group-dynamic .form-control,
.input-group.input-group-dynamic .form-control:focus,
.input-group.input-group-static .form-control,
.input-group.input-group-static .form-control:focus {
  background-image: linear-gradient(0deg, #e91e63 2px, rgba(156, 39, 176, 0) 0), linear-gradient(0deg, #d2d2d2 1px, rgba(209, 209, 209, 0) 0);
  border-radius: 0 !important;
}

.input-group.input-group-dynamic .form-control:focus,
.input-group.input-group-static .form-control:focus {
  background-size: 100% 100%, 100% 100%;
}

.input-group.input-group-dynamic .form-control[disabled],
.input-group.input-group-static .form-control[disabled] {
  cursor: not-allowed;
  background-image: linear-gradient(0deg, #e91e63 2px, rgba(156, 39, 176, 0) 0), linear-gradient(0deg, #f0f2f5 1px, rgba(209, 209, 209, 0) 0) !important;
}

.input-group.input-group-dynamic .input-group-text,
.input-group.input-group-static .input-group-text {
  border-right: 0;
}

.input-group.input-group-dynamic.is-focused .form-label,
.input-group.input-group-dynamic.is-filled .form-label,
.input-group.input-group-static.is-focused .form-label,
.input-group.input-group-static.is-filled .form-label {
  font-size: 0.6875rem !important;
}

.input-group.input-group-dynamic.is-focused .form-label,
.input-group.input-group-static.is-focused .form-label {
  top: -0.7rem;
}

.input-group.input-group-dynamic.is-focused label,
.input-group.input-group-static.is-focused label {
  color: #e91e63;
}

.input-group.input-group-dynamic.is-focused.is-valid label,
.input-group.input-group-static.is-focused.is-valid label {
  color: #4CAF50;
}

.input-group.input-group-dynamic.is-focused.is-valid .form-control,
.input-group.input-group-dynamic.is-focused.is-valid .form-control:focus,
.input-group.input-group-static.is-focused.is-valid .form-control,
.input-group.input-group-static.is-focused.is-valid .form-control:focus {
  background-image: linear-gradient(0deg, #4CAF50 2px, rgba(156, 39, 176, 0) 0), linear-gradient(0deg, #d2d2d2 1px, rgba(209, 209, 209, 0) 0);
  border-radius: 0 !important;
}

.input-group.input-group-dynamic.is-focused.is-invalid label,
.input-group.input-group-static.is-focused.is-invalid label {
  color: #F44335;
}

.input-group.input-group-dynamic.is-focused.is-invalid .form-control,
.input-group.input-group-dynamic.is-focused.is-invalid .form-control:focus,
.input-group.input-group-static.is-focused.is-invalid .form-control,
.input-group.input-group-static.is-focused.is-invalid .form-control:focus {
  background-image: linear-gradient(0deg, #F44335 2px, rgba(156, 39, 176, 0) 0), linear-gradient(0deg, #d2d2d2 1px, rgba(209, 209, 209, 0) 0);
  border-radius: 0 !important;
}

.input-group.input-group-dynamic.is-valid .form-control,
.input-group.input-group-dynamic.is-valid .form-control:focus,
.input-group.input-group-static.is-valid .form-control,
.input-group.input-group-static.is-valid .form-control:focus {
  background-image: linear-gradient(0deg, #4CAF50 2px, rgba(156, 39, 176, 0) 0), linear-gradient(0deg, #d2d2d2 1px, rgba(209, 209, 209, 0) 0);
  border-radius: 0 !important;
}

.input-group.input-group-dynamic.is-invalid .form-control,
.input-group.input-group-dynamic.is-invalid .form-control:focus,
.input-group.input-group-static.is-invalid .form-control,
.input-group.input-group-static.is-invalid .form-control:focus {
  background-image: linear-gradient(0deg, #F44335 2px, rgba(156, 39, 176, 0) 0), linear-gradient(0deg, #d2d2d2 1px, rgba(209, 209, 209, 0) 0);
  border-radius: 0 !important;
}

.input-group.input-group-dynamic.is-filled.is-focused .form-label,
.input-group.input-group-dynamic.is-filled .form-label,
.input-group.input-group-static.is-filled.is-focused .form-label,
.input-group.input-group-static.is-filled .form-label {
  top: -1rem;
}

.input-group.input-group-outline .form-control {
  background: none;
  border: 1px solid #d2d6da;
  border-radius: 0.375rem;
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
  padding: 0.625rem 0.75rem !important;
  line-height: 1.3 !important;
}

.input-group.input-group-outline .form-control.form-control-lg {
  padding: 0.75rem 0.75rem !important;
}

.input-group.input-group-outline .form-control.form-control-sm {
  padding: 0.25rem 0.75rem !important;
}

.input-group.input-group-outline .form-control[disabled] {
  cursor: not-allowed;
  border-style: dashed;
}

.input-group.input-group-outline .form-label {
  display: flex;
  line-height: 3.925 !important;
  top: -0.375rem;
  margin-bottom: 0;
}

.input-group.input-group-outline .form-label:before {
  content: "";
  margin-right: 4px;
  border-left: solid 1px transparent;
  border-radius: 4px 0;
}

.input-group.input-group-outline .form-label:after {
  content: "";
  flex-grow: 1;
  margin-left: 4px;
  border-right: solid 1px transparent;
  border-radius: 0 5px;
}

.input-group.input-group-outline .form-label:before,
.input-group.input-group-outline .form-label:after {
  content: "";
  border-top: solid 1px;
  border-top-color: #d2d6da;
  pointer-events: none;
  margin-top: 0.375rem;
  box-sizing: border-box;
  display: block;
  height: 0.5rem;
  width: 0.625rem;
  border-width: 1px 0 0;
  border-color: transparent;
}

.input-group.input-group-outline.is-focused .form-label+.form-control,
.input-group.input-group-outline.is-filled .form-label+.form-control {
  border-color: #e91e63 !important;
  border-top-color: transparent !important;
  box-shadow: inset 1px 0 #e91e63, inset -1px 0 #e91e63, inset 0 -1px #e91e63;
}

.input-group.input-group-outline.is-focused .form-label,
.input-group.input-group-outline.is-filled .form-label {
  width: 100%;
  height: 100%;
  font-size: 0.6875rem !important;
  color: #e91e63;
  display: flex;
  line-height: 1.25 !important;
}

.input-group.input-group-outline.is-focused .form-label:before,
.input-group.input-group-outline.is-focused .form-label:after,
.input-group.input-group-outline.is-filled .form-label:before,
.input-group.input-group-outline.is-filled .form-label:after {
  opacity: 1;
}

.input-group.input-group-outline.is-focused .form-label:before,
.input-group.input-group-outline.is-focused .form-label:after,
.input-group.input-group-outline.is-filled .form-label:before,
.input-group.input-group-outline.is-filled .form-label:after {
  border-top-color: #e91e63;
  box-shadow: inset 0 1px #e91e63;
}

.input-group.input-group-outline.is-valid .form-control {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 8'%3e%3cpath fill='%2366d432' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1rem 1rem;
}

.input-group.input-group-outline.is-valid.is-focused .form-label+.form-control,
.input-group.input-group-outline.is-valid.is-filled .form-label+.form-control {
  border-color: #4CAF50 !important;
  box-shadow: inset 1px 0 #4CAF50, inset -1px 0 #4CAF50, inset 0 -1px #4CAF50;
  border-top-color: transparent !important;
}

.input-group.input-group-outline.is-valid.is-focused .form-label,
.input-group.input-group-outline.is-valid.is-filled .form-label {
  color: #4CAF50;
}

.input-group.input-group-outline.is-valid.is-focused .form-label:before,
.input-group.input-group-outline.is-valid.is-focused .form-label:after,
.input-group.input-group-outline.is-valid.is-filled .form-label:before,
.input-group.input-group-outline.is-valid.is-filled .form-label:after {
  border-top-color: #4CAF50;
  box-shadow: inset 0 1px #4CAF50;
}

.input-group.input-group-outline.is-invalid .form-control {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23fd5c70' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fd5c70' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1rem 1rem;
}

.input-group.input-group-outline.is-invalid.is-focused .form-label+.form-control,
.input-group.input-group-outline.is-invalid.is-filled .form-label+.form-control {
  border-color: #F44335 !important;
  box-shadow: inset 1px 0 #F44335, inset -1px 0 #F44335, inset 0 -1px #F44335;
  border-top-color: transparent !important;
}

.input-group.input-group-outline.is-invalid.is-focused .form-label,
.input-group.input-group-outline.is-invalid.is-filled .form-label {
  color: #F44335;
}

.input-group.input-group-outline.is-invalid.is-focused .form-label:before,
.input-group.input-group-outline.is-invalid.is-focused .form-label:after,
.input-group.input-group-outline.is-invalid.is-filled .form-label:before,
.input-group.input-group-outline.is-invalid.is-filled .form-label:after {
  border-top-color: #F44335;
  box-shadow: inset 0 1px #F44335;
}

.input-group.input-group-outline.input-group-sm .form-label,
.input-group.input-group-outline.input-group-sm label,
.input-group.input-group-dynamic.input-group-sm .form-label,
.input-group.input-group-dynamic.input-group-sm label,
.input-group.input-group-static.input-group-sm .form-label,
.input-group.input-group-static.input-group-sm label {
  font-size: 0.75rem;
}

.input-group.input-group-outline.input-group-lg .form-label,
.input-group.input-group-outline.input-group-lg label,
.input-group.input-group-dynamic.input-group-lg .form-label,
.input-group.input-group-dynamic.input-group-lg label,
.input-group.input-group-static.input-group-lg .form-label,
.input-group.input-group-static.input-group-lg label {
  font-size: 0.975rem;
}

.input-group.input-group-static .form-control {
  width: 100%;
}

.input-group.input-group-static label {
  margin-left: 0;
  margin-bottom: 0;
}

.form-check:not(.form-switch) .form-check-input {
  float: initial !important;
  margin-left: auto !important;
}

.form-check:not(.form-switch) .form-check-input[type="checkbox"],
.form-check:not(.form-switch) .form-check-input[type="radio"] {
  border: 1px solid #d1d7e1;
  margin-top: 0.25rem;
  position: relative;
}

.form-check:not(.form-switch) .form-check-input[type="checkbox"]:checked,
.form-check:not(.form-switch) .form-check-input[type="radio"]:checked {
  border-color: #e91e63;
}

.form-check:not(.form-switch) .form-check-input[type="checkbox"] {
  background-image: none;
}

.form-check:not(.form-switch) .form-check-input[type="checkbox"]:after {
  transition: opacity 0.25s ease-in-out;
  font-family: "FontAwesome";
  content: "\f00c";
  width: 100%;
  height: 100%;
  color: #fff;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.67rem;
  opacity: 0;
}

.form-check:not(.form-switch) .form-check-input[type="checkbox"]:checked {
  background: #e91e63;
}

.form-check:not(.form-switch) .form-check-input[type="checkbox"]:checked:after {
  opacity: 1;
}

.form-check:not(.form-switch) .form-check-input[type="radio"] {
  transition: border 0s;
  background: transparent;
}

.form-check:not(.form-switch) .form-check-input[type="radio"]:after {
  transition: opacity 0.25s ease-in-out;
  content: "";
  position: absolute;
  width: 0.8375rem;
  height: 0.8375rem;
  border-radius: 50%;
  background-image: linear-gradient(195deg, #EC407A 0%, #D81B60 100%), var(--bs-gradient);
  opacity: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.form-check:not(.form-switch) .form-check-input[type="radio"]:checked {
  padding: 6px;
}

.form-check:not(.form-switch) .form-check-input[type="radio"]:checked:after {
  opacity: 1;
}

.form-check:not(.form-switch) .form-check-input[type="radio"]:active {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06), 0 0 0 12px rgba(53, 71, 102, 0.1);
  border-radius: 50rem;
  transition: 0.05s ease;
}

.form-check-label,
.form-check-input[type="checkbox"] {
  cursor: pointer;
}

.form-check-label {
  font-size: 0.875rem;
  font-weight: 400;
}

.form-check-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-switch .form-check-input {
  position: relative;
  background-color: #ced4da;
  height: 0.9375rem;
  width: 1.875rem;
}

.form-switch .form-check-input:after {
  transition: transform 0.25s ease-in-out, background-color 0.25s ease-in-out;
  content: "";
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  border: 1px solid #ced4da;
  position: absolute;
  background-color: #fff;
  transform: translateX(1px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  top: -2.5px;
  left: -5px;
}

.form-switch .form-check-input:checked:after {
  transform: translateX(21px);
  border-color: #42424a;
}

.form-switch .form-check-input:checked {
  border-color: #42424a;
  background-color: #42424a;
}

.form-switch .form-check-input:checked:active:after {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06), 0 0 0 15px rgba(53, 71, 102, 0.1);
}

.form-switch .form-check-input:active:after {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06), 0 0 0 15px rgba(0, 0, 0, 0.1);
}

.form-select {
  transition: 0.2s ease;
}

label,
.form-label {
  font-size: 0.875rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
  color: #7b809a;
  margin-left: 0.25rem;
}

.input-group .form-label {
  position: absolute;
  top: 0.6125rem;
  margin-left: 0;
  transition: 0.2s ease all;
}

.form-control {
  border: none;
}

.form-control.is-invalid {
  border: 1px solid #d2d6da;
  padding: 0.625rem 0.75rem;
  line-height: 1.3 !important;
}

.form-control.is-invalid:focus {
  box-shadow: 0 0 0 2px rgba(253, 92, 112, 0.6);
}

.form-control.is-valid {
  border: 1px solid #d2d6da;
  padding: 0.625rem 0.75rem;
  line-height: 1.3 !important;
}

.form-control.is-valid:focus {
  box-shadow: 0 0 0 2px rgba(102, 212, 50, 0.65);
}

.form-control[disabled] {
  padding: 0.625rem 0.75rem;
  line-height: 1.45 !important;
}

.input-group .input-group-text {
  position: absolute;
  padding: .75rem 0;
  right: 0;
  border-right: 0 !important;
}

.input-group .input-group-text i {
  color: #6c757d;
}

.input-group.input-group-static .input-group-text {
  bottom: 0;
}

.footer .nav-link {
  color: #344767;
  font-weight: 400;
  font-size: 0.875rem;
  padding-top: 0;
  padding-bottom: 0.25rem;
}

.footer .nav-link:hover {
  opacity: 1 !important;
  transition: opacity 0.3 ease;
}

.footer .footer-logo {
  max-width: 2rem;
}

.bg-gradient-primary {
  background-image: linear-gradient(195deg, #EC407A 0%, #D81B60 100%);
}

.bg-gradient-secondary {
  background-image: linear-gradient(195deg, #747b8a 0%, #495361 100%);
}

.bg-gradient-success {
  background-image: linear-gradient(195deg, #66BB6A 0%, #43A047 100%);
}

.bg-gradient-info {
  background-image: linear-gradient(195deg, #49a3f1 0%, #1A73E8 100%);
}

.bg-gradient-warning {
  background-image: linear-gradient(195deg, #FFA726 0%, #FB8C00 100%);
}

.bg-gradient-danger {
  background-image: linear-gradient(195deg, #EF5350 0%, #E53935 100%);
}

.bg-gradient-light {
  background-image: linear-gradient(195deg, #EBEFF4 0%, #CED4DA 100%);
}

.bg-gradient-dark {
  background-image: linear-gradient(195deg, #42424a 0%, #191919 100%);
}

.bg-gradient-faded-primary {
  background-image: radial-gradient(370px circle at 80% 50%, rgba(233, 30, 99, 0.6) 0, #c1134e 100%);
}

.bg-gradient-faded-secondary {
  background-image: radial-gradient(370px circle at 80% 50%, rgba(, 128, 154, 0.6) 0, #626780 100%);
}

.bg-gradient-faded-success {
  background-image: radial-gradient(370px circle at 80% 50%, rgba(76, 175, 80, 0.6) 0, #3d8b40 100%);
}

.bg-gradient-faded-info {
  background-image: radial-gradient(370px circle at 80% 50%, rgba(26, 115, 232, 0.6) 0, #135cbc 100%);
}

.bg-gradient-faded-warning {
  background-image: radial-gradient(370px circle at 80% 50%, rgba(251, 140, 0, 0.6) 0, #c87000 100%);
}

.bg-gradient-faded-danger {
  background-image: radial-gradient(370px circle at 80% 50%, rgba(244, 67, 53, 0.6) 0, #e91d0d 100%);
}

.bg-gradient-faded-light {
  background-image: radial-gradient(370px circle at 80% 50%, rgba(240, 242, 245, 0.6) 0, #d1d7e1 100%);
}

.bg-gradient-faded-dark {
  background-image: radial-gradient(370px circle at 80% 50%, rgba(52, 71, 103, 0.6) 0, #233045 100%);
}

.bg-gradient-faded-white {
  background-image: radial-gradient(370px circle at 80% 50%, rgba(255, 255, 255, 0.6) 0, #e6e6e6 100%);
}

.bg-gradient-faded-dark-blue {
  background-image: radial-gradient(370px circle at 80% 50%, rgba(26, 35, 126, 0.6) 0, #111754 100%);
}

.bg-gradient-faded-primary-vertical {
  background-image: radial-gradient(200px circle at 50% 70%, rgba(233, 30, 99, 0.3) 0, #e91e63 100%);
}

.bg-gradient-faded-secondary-vertical {
  background-image: radial-gradient(200px circle at 50% 70%, rgba(, 128, 154, 0.3) 0, #7b809a 100%);
}

.bg-gradient-faded-success-vertical {
  background-image: radial-gradient(200px circle at 50% 70%, rgba(76, 175, 80, 0.3) 0, #4CAF50 100%);
}

.bg-gradient-faded-info-vertical {
  background-image: radial-gradient(200px circle at 50% 70%, rgba(26, 115, 232, 0.3) 0, #1A73E8 100%);
}

.bg-gradient-faded-warning-vertical {
  background-image: radial-gradient(200px circle at 50% 70%, rgba(251, 140, 0, 0.3) 0, #fb8c00 100%);
}

.bg-gradient-faded-danger-vertical {
  background-image: radial-gradient(200px circle at 50% 70%, rgba(244, 67, 53, 0.3) 0, #F44335 100%);
}

.bg-gradient-faded-light-vertical {
  background-image: radial-gradient(200px circle at 50% 70%, rgba(240, 242, 245, 0.3) 0, #f0f2f5 100%);
}

.bg-gradient-faded-dark-vertical {
  background-image: radial-gradient(200px circle at 50% 70%, rgba(52, 71, 103, 0.3) 0, #344767 100%);
}

.bg-gradient-faded-white-vertical {
  background-image: radial-gradient(200px circle at 50% 70%, rgba(255, 255, 255, 0.3) 0, #fff 100%);
}

.bg-gradient-faded-dark-blue-vertical {
  background-image: radial-gradient(200px circle at 50% 70%, rgba(26, 35, 126, 0.3) 0, #1A237E 100%);
}

.material-icons {
  font-family: 'Material Icons Round';
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.nav.nav-pills .nav-link .material-icons {
  top: 3px;
}

.icon-shape {
  width: 48px;
  height: 48px;
  background-position: center;
  border-radius: 0.5rem;
}

.icon-shape i {
  color: #fff;
  opacity: 0.8;
  top: 11px;
  position: relative;
}

.icon-shape .ni {
  top: 14px;
}

.icon-xxs {
  width: 20px;
  height: 20px;
}

.icon-xxs i {
  top: 0;
  font-size: 0.65rem;
}

.icon-xs {
  width: 24px;
  height: 24px;
}

.icon-xs i {
  top: -1px;
  font-size: 0.75rem;
}

.icon-sm {
  width: 32px;
  height: 32px;
}

.icon-sm i {
  top: 4px;
  font-size: 0.875rem;
}

.icon-md {
  width: 48px;
  height: 48px;
}

.icon-md i {
  top: 30%;
  font-size: 1.125rem;
}

.icon-md.icon-striped {
  background-position-x: 85px;
  background-position-y: 85px;
}

.icon-md.icon-striped i {
  top: 11%;
  margin-left: -10px;
  font-size: 0.875rem;
}

.icon-lg {
  width: 64px;
  height: 64px;
}

.icon-lg i {
  top: 31%;
  font-size: 1.5rem;
}

.icon-lg.icon-striped {
  background-position-x: 111px;
  background-position-y: 111px;
}

.icon-lg.icon-striped i {
  top: 21%;
  margin-left: -15px;
}

.icon-xl {
  width: 100px;
  height: 100px;
  border-radius: 0.5rem;
}

.icon-xl i {
  top: 35%;
  font-size: 2.1rem;
}

.icon-xl.icon-striped {
  background-position-x: 80px;
  background-position-y: 80px;
}

.icon-xl.icon-striped i {
  top: 30%;
  margin-left: -15px;
}

.info-horizontal {
  text-align: left !important;
}

.info-horizontal .icon {
  float: left;
}

.info-horizontal .description {
  overflow: hidden;
}

svg.text-primary .color-foreground {
  fill: #EC407A;
}

svg.text-primary .color-background {
  fill: #D81B60;
}

svg.text-secondary .color-foreground {
  fill: #747b8a;
}

svg.text-secondary .color-background {
  fill: #495361;
}

svg.text-info .color-foreground {
  fill: #49a3f1;
}

svg.text-info .color-background {
  fill: #1A73E8;
}

svg.text-warning .color-foreground {
  fill: #FFA726;
}

svg.text-warning .color-background {
  fill: #FB8C00;
}

svg.text-danger .color-foreground {
  fill: #EF5350;
}

svg.text-danger .color-background {
  fill: #E53935;
}

svg.text-success .color-foreground {
  fill: #66BB6A;
}

svg.text-success .color-background {
  fill: #43A047;
}

svg.text-dark .color-foreground {
  fill: #42424a;
}

svg.text-dark .color-background {
  fill: #191919;
}

html {
  border: none !important;
}

.blur {
  box-shadow: inset 0px 0px 2px #fefefed1;
  -webkit-backdrop-filter: saturate(200%) blur(30px);
  backdrop-filter: saturate(200%) blur(30px);
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.blur.saturation-less {
  -webkit-backdrop-filter: saturate(20%) blur(30px);
  backdrop-filter: saturate(20%) blur(30px);
}

.blur.blur-rounded {
  border-radius: 40px;
}

.blur.blur-light {
  background-color: rgba(255, 255, 255, 0.4);
}

.blur.blur-dark {
  background-color: rgba(0, 0, 0, 0.3);
}

.shadow-blur {
  box-shadow: inset 0 0px 1px 1px rgba(254, 254, 254, 0.9), 0 20px 27px 0 rgba(0, 0, 0, 0.05) !important;
}

.shadow-card {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}

.navbar-blur {
  -webkit-backdrop-filter: saturate(200%) blur(30px);
  backdrop-filter: saturate(200%) blur(30px);
  background-color: rgba(255, 255, 255, 0.58) !important;
}

.blur-section {
  -webkit-backdrop-filter: saturate(200%) blur(30px);
  backdrop-filter: saturate(200%) blur(30px);
}

.blur-section.blur-gradient-primary {
  background-image: linear-gradient(195deg, rgba(236, 64, 122, 0.95) 0%, rgba(216, 27, 96, 0.95) 100%);
}

*.move-on-hover {
  -webkit-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  overflow: hidden;
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: perspective(999px) rotateX(0deg) translate3d(0, 0, 0);
  transform: perspective(999px) rotateX(0deg) translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform, box-shadow;
}

*.move-on-hover:hover {
  -webkit-transform: perspective(999px) rotateX(7deg) translate3d(0px, -4px, 5px);
  transform: perspective(999px) rotateX(7deg) translate3d(0px, -4px, 5px);
}

*.gradient-animation {
  background: linear-gradient(-45deg, #49a3f1, #F44335, #fb8c00, #EC407A, #344767);
  background-size: 400% 400% !important;
  animation: gradient 10s ease infinite;
}

hr {
  border-top: none;
  height: 1px;
}

hr.vertical {
  position: absolute;
  background-color: transparent;
  height: 100%;
  right: 0;
  top: 0;
  width: 1px;
}

hr.vertical.light {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff, rgba(255, 255, 255, 0));
}

hr.vertical.dark {
  background-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.4), transparent);
}

hr.vertical.gray-light {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
}

hr.horizontal {
  background-color: transparent;
}

hr.horizontal.light {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff, rgba(255, 255, 255, 0));
}

hr.horizontal.dark {
  background-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.4), transparent);
}

hr.horizontal.gray-light {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
}

.lock-size {
  width: 1.7rem;
  height: 1.7rem;
}

.border-radius-xs {
  border-radius: 0.1rem;
}

.border-radius-sm {
  border-radius: 0.125rem;
}

.border-radius-md {
  border-radius: 0.375rem;
}

.border-radius-lg {
  border-radius: 0.5rem;
}

.border-radius-xl {
  border-radius: 0.75rem;
}

.border-radius-2xl {
  border-radius: 1rem;
}

.border-radius-section {
  border-radius: 10rem;
}

.border-bottom-end-radius-0 {
  border-bottom-right-radius: 0;
}

.border-top-end-radius-0 {
  border-top-right-radius: 0;
}

.border-bottom-start-radius-0 {
  border-bottom-left-radius: 0;
}

.border-top-start-radius-0 {
  border-top-left-radius: 0;
}

.border-dashed {
  border-style: dashed;
}

.z-index-sticky {
  z-index: 1020;
}

.waves {
  position: relative;
  width: 100%;
  height: 16vh;
  margin-bottom: -7px;
  /*Fix for safari gap*/
  min-height: 100px;
  max-height: 150px;
}

.waves.waves-sm {
  height: 50px;
  min-height: 50px;
}

.waves.no-animation .moving-waves>use {
  animation: none;
}

.wave-rotate {
  transform: rotate(180deg);
}

/* Animation for the waves */
.moving-waves>use {
  animation: move-forever 40s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.moving-waves>use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 11s;
}

.moving-waves>use:nth-child(2) {
  animation-delay: -4s;
  animation-duration: 13s;
}

.moving-waves>use:nth-child(3) {
  animation-delay: -3s;
  animation-duration: 15s;
}

.moving-waves>use:nth-child(4) {
  animation-delay: -4s;
  animation-duration: 20s;
}

.moving-waves>use:nth-child(5) {
  animation-delay: -4s;
  animation-duration: 25s;
}

.moving-waves>use:nth-child(6) {
  animation-delay: -3s;
  animation-duration: 30s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
}

/*Shrinking for mobile*/
@media (max-width: 767.98px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }

  hr.horizontal {
    background-color: transparent;
  }

  hr.horizontal:not(.dark) {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), white, rgba(255, 255, 255, 0));
  }

  hr.horizontal.vertical {
    transform: rotate(90deg);
  }

  hr.horizontal.dark {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
  }
}

.overflow-visible {
  overflow: visible !important;
}

.popover .popover-header {
  font-weight: 600;
}

.bg-cover {
  background-size: cover;
}

.mask {
  position: absolute;
  background-size: cover;
  background-position: center center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
}

.cursor-pointer {
  cursor: pointer;
}

.transform-translate-50 {
  transform: translate(0, -50%);
}

@media (min-width: 992px) {
  .virtual-reality .sidenav {
    animation-name: fadeInBottom;
    animation-fill-mode: both;
    animation-duration: 1.5s;
    transform: scale(0.6);
    left: 18% !important;
    position: absolute;
  }
}

.choices .choices__list {
  background: no-repeat bottom, 50% calc(100% - 1px);
  background-size: 0 100%, 100% 100%;
  transition: 0.2s ease;
}

.choices .choices__list.choices__list--single .choices__item--selectable {
  margin-bottom: 0.5rem;
}

.choices .choices__list.choices__list--single,
.choices .choices__list.choices__list--single:focus {
  background-image: linear-gradient(0deg, #e91e63 2px, rgba(156, 39, 176, 0) 0), linear-gradient(0deg, #d2d2d2 1px, rgba(209, 209, 209, 0) 0);
}

.choices .choices__list.choices__list--dropdown {
  background: #fff;
}

.choices.is-focused .choices__list {
  background-size: 100% 100%, 100% 100%;
}

.border-right-after:after {
  content: "";
  position: absolute;
  right: 0;
  top: 3vh;
  height: 70%;
  width: 50%;
  border-right: 1px solid #dee2e6;
}

.rotate-caret .collapse .nav-link:after {
  margin-right: auto;
  margin-left: 0;
}

@media (min-width: 1200px) {
  .g-sidenav-hidden.rtl .main-content {
    margin-right: 7rem !important;
  }
}

.rtl .ps__rail-y {
  right: auto !important;
  left: 0;
}

.rtl .start-0 {
  right: 0 !important;
}

.rtl .end-0 {
  left: 0 !important;
}

.bullets {
  position: sticky;
  top: 30%;
  height: 0;
  transform: translate(-50%, -50%);
  margin-left: auto;
}

.bullets ul {
  display: flex;
  flex-direction: column;
  align-items: end;
  list-style-type: none;
  margin-right: -1rem;
}

.bullets ul li .indicator {
  cursor: pointer;
  margin-top: 1rem;
  display: block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #e91e63;
}

.bullets ul li .active {
  transform: scale(1.3);
  transition: transform 200ms ease-in-out;
}

.img-material-tw {
  object-fit: cover;
}

.navbar {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);
}

.navbar .navbar-brand {
  color: #344767;
  font-size: 0.875rem;
}

.navbar .nav-link {
  color: #344767;
  padding: 0.5rem 1rem;
  font-weight: 400;
  font-size: 0.875rem;
}

.navbar.navbar-absolute {
  position: absolute;
  width: 100%;
  z-index: 1;
}

.navbar.navbar-transparent .nav-link,
.navbar.navbar-transparent .nav-link i {
  color: #fff;
}

.navbar.navbar-transparent .nav-link:hover,
.navbar.navbar-transparent .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar.navbar-transparent .navbar-toggler .navbar-toggler-icon .navbar-toggler-bar {
  background: #fff;
}

.navbar.navbar-transparent .navbar-collapse {
  border-radius: 0.75rem;
}

.navbar.navbar-dark .navbar-collapse.show .dropdown-header.text-dark,
.navbar.navbar-dark .navbar-collapse.collapsing .dropdown-header.text-dark {
  color: #fff !important;
}

.navbar .sidenav-toggler-inner {
  width: 18px;
}

.navbar .sidenav-toggler-inner .sidenav-toggler-line {
  transition: all 0.15s ease;
  background: #7b809a;
  border-radius: 0.1rem;
  position: relative;
  display: block;
  height: 2px;
}

.navbar .sidenav-toggler-inner .sidenav-toggler-line:not(:last-child) {
  margin-bottom: 3px;
}

.g-sidenav-show.g-sidenav-pinned .navbar .sidenav-toggler-inner .sidenav-toggler-line:first-child,
.g-sidenav-show.g-sidenav-pinned .navbar .sidenav-toggler-inner .sidenav-toggler-line:last-child {
  width: 13px;
  transform: translateX(5px);
}

.navbar-light {
  background-color: #fff !important;
}

.navbar-light .navbar-toggler {
  border: none;
}

.navbar-light .navbar-toggler:focus {
  box-shadow: none;
}

.navbar-toggler .navbar-toggler-icon {
  background-image: none;
}

.navbar-toggler .navbar-toggler-icon .navbar-toggler-bar {
  display: block;
  position: relative;
  width: 22px;
  height: 1px;
  border-radius: 1px;
  background: #6c757d;
  transition: all 0.2s;
  margin: 0 auto;
}

.navbar-toggler .navbar-toggler-icon .navbar-toggler-bar.bar2,
.navbar-toggler .navbar-toggler-icon .navbar-toggler-bar.bar3 {
  margin-top: 7px;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-bar.bar1 {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
  margin-top: 4px;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-bar.bar2 {
  opacity: 0;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-bar.bar3 {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
  margin-top: 3px;
}

@media (max-width: 991.98px) {
  .navbar.navbar-transparent .navbar-collapse {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .navbar.navbar-transparent .navbar-collapse.collapsing {
    background: #fff;
  }

  .navbar.navbar-transparent .navbar-collapse.show {
    background: #fff;
  }

  .navbar.navbar-transparent .navbar-collapse.show .nav-link,
  .navbar.navbar-transparent .navbar-collapse.show i {
    color: #344767;
  }

  .g-sidenav-show .navbar:not(.sidenav).navbar-main .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .g-sidenav-show .navbar:not(.sidenav).navbar-main .navbar-nav {
    flex-direction: row;
  }

  .navbar .navbar-collapse .navbar-nav .dropdown .dropdown-menu {
    box-shadow: none !important;
  }

  .navbar .navbar-collapse .navbar-nav .dropdown .dropdown-menu:before {
    display: none !important;
  }
}

@media (max-width: 767.98px) {
  .navbar-collapse {
    position: relative;
  }

  .navbar-collapse .navbar-nav {
    width: 100%;
  }

  .navbar-collapse .navbar-nav .nav-item.dropdown {
    position: static;
  }

  .navbar-collapse .navbar-nav .nav-item.dropdown .dropdown-menu {
    left: 0;
    right: 0;
  }

  .navbar-collapse .navbar-nav .nav-item.dropdown .dropdown-menu.show:before {
    content: none;
  }
}

@media (max-width: 575.98px) {
  .navbar-nav .nav-item.dropdown .dropdown-menu {
    left: 0;
    right: auto;
  }
}

.navbar-vertical .navbar-brand>img,
.navbar-vertical .navbar-brand-img {
  max-width: 100%;
  max-height: 2rem;
}

.navbar-vertical .navbar-nav .nav-link {
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: 300;
  color: #fff;
}

.navbar-vertical .navbar-nav .nav-link>i {
  min-width: 1.8rem;
  font-size: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
}

.navbar-vertical .navbar-nav .nav-link .dropdown-menu {
  border: none;
}

.navbar-vertical .navbar-nav .nav-link .dropdown-menu .dropdown-menu {
  margin-left: 0.5rem;
}

.navbar-vertical .navbar-nav .nav-link .avatar {
  width: 1.875rem;
  height: 1.875rem;
}

.navbar-vertical .navbar-nav .nav-sm .nav-link {
  font-size: 0.8125rem;
}

.navbar-vertical .navbar-nav .nav-link {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.navbar-vertical .navbar-heading {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}

.navbar-vertical.navbar-expand-xs {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 15.625rem !important;
  overflow-y: auto;
  padding: 0;
  box-shadow: none;
}

.navbar-vertical.navbar-expand-xs .navbar-collapse {
  display: block;
  overflow: auto;
  height: calc(100vh - 200px);
}

.navbar-vertical.navbar-expand-xs>[class*="container"] {
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;
  padding-left: 0;
  padding-right: 0;
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .navbar-vertical.navbar-expand-xs>[class*="container"] {
    min-height: none;
    height: 100%;
  }
}

.navbar-vertical.navbar-expand-xs.fixed-start {
  left: 0;
}

.navbar-vertical.navbar-expand-xs.fixed-end {
  right: 0;
}

.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin: 0 1rem;
  margin-bottom: 1.5px;
}

.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link .nav-link-text,
.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link .sidenav-mini-icon,
.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link .sidenav-normal,
.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link i {
  pointer-events: none;
}

.navbar-vertical.navbar-expand-xs .navbar-nav .nav-item {
  width: 100%;
}

.navbar-vertical.navbar-expand-xs .navbar-nav>.nav-item {
  margin-top: 0.125rem;
}

.navbar-vertical.navbar-expand-xs .navbar-nav>.nav-item .icon .ni {
  top: 0;
}

.navbar-vertical.navbar-expand-xs .lavalamp-object {
  width: calc(100% - 1rem) !important;
  background: theme-color("primary");
  color: color-yiq(#e91e63);
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.125rem;
}

.navbar-vertical.navbar-expand-xs .navbar-nav .nav .nav-link {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 15px;
}

.navbar-vertical.navbar-expand-xs .navbar-nav .nav .nav-link>span.sidenav-normal {
  transition: all 0.1s ease 0s;
}

.navbar-vertical.navbar-expand-xs .navbar-nav .nav .nav-link.active {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}



@media all and (min-width: 576px) and (-ms-high-contrast: none),
(min-width: 576px) and (-ms-high-contrast: active) {
  .navbar-vertical.navbar-expand-sm>[class*="container"] {
    min-height: none;
    height: 100%;
  }
}


  .navbar-vertical.navbar-expand-sm.fixed-end {
    right: 0;
  }

  .navbar-vertical.navbar-expand-sm .navbar-nav .nav-link {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    margin: 0 1rem;
    margin-bottom: 1.5px;
  }

  .navbar-vertical.navbar-expand-sm .navbar-nav .nav-link .nav-link-text,
  .navbar-vertical.navbar-expand-sm .navbar-nav .nav-link .sidenav-mini-icon,
  .navbar-vertical.navbar-expand-sm .navbar-nav .nav-link .sidenav-normal,
  .navbar-vertical.navbar-expand-sm .navbar-nav .nav-link i {
    pointer-events: none;
  }

  .navbar-vertical.navbar-expand-sm .navbar-nav .nav-item {
    width: 100%;
  }

  .navbar-vertical.navbar-expand-sm .navbar-nav>.nav-item {
    margin-top: 0.125rem;
  }

  .navbar-vertical.navbar-expand-sm .navbar-nav>.nav-item .icon .ni {
    top: 0;
  }

  .navbar-vertical.navbar-expand-sm .lavalamp-object {
    width: calc(100% - 1rem) !important;
    background: theme-color("primary");
    color: color-yiq(#e91e63);
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.125rem;
  }

  .navbar-vertical.navbar-expand-sm .navbar-nav .nav .nav-link {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 15px;
  }

  .navbar-vertical.navbar-expand-sm .navbar-nav .nav .nav-link>span.sidenav-normal {
    transition: all 0.1s ease 0s;
  }

  .navbar-vertical.navbar-expand-sm .navbar-nav .nav .nav-link.active {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }


@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 15.625rem !important;
    overflow-y: auto;
    padding: 0;
    box-shadow: none;
  }

  .navbar-vertical.navbar-expand-md .navbar-collapse {
    display: block;
    overflow: auto;
    height: calc(100vh - 200px);
  }

  .navbar-vertical.navbar-expand-md>[class*="container"] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

@media all and (min-width: 768px) and (-ms-high-contrast: none),
(min-width: 768px) and (-ms-high-contrast: active) {
  .navbar-vertical.navbar-expand-md>[class*="container"] {
    min-height: none;
    height: 100%;
  }
}

@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md.fixed-start {
    left: 0;
  }

  .navbar-vertical.navbar-expand-md.fixed-end {
    right: 0;
  }

  .navbar-vertical.navbar-expand-md .navbar-nav .nav-link {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    margin: 0 1rem;
    margin-bottom: 1.5px;
  }

  .navbar-vertical.navbar-expand-md .navbar-nav .nav-link .nav-link-text,
  .navbar-vertical.navbar-expand-md .navbar-nav .nav-link .sidenav-mini-icon,
  .navbar-vertical.navbar-expand-md .navbar-nav .nav-link .sidenav-normal,
  .navbar-vertical.navbar-expand-md .navbar-nav .nav-link i {
    pointer-events: none;
  }

  .navbar-vertical.navbar-expand-md .navbar-nav .nav-item {
    width: 100%;
  }

  .navbar-vertical.navbar-expand-md .navbar-nav>.nav-item {
    margin-top: 0.125rem;
  }

  .navbar-vertical.navbar-expand-md .navbar-nav>.nav-item .icon .ni {
    top: 0;
  }

  .navbar-vertical.navbar-expand-md .lavalamp-object {
    width: calc(100% - 1rem) !important;
    background: theme-color("primary");
    color: color-yiq(#e91e63);
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.125rem;
  }

  .navbar-vertical.navbar-expand-md .navbar-nav .nav .nav-link {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 15px;
  }

  .navbar-vertical.navbar-expand-md .navbar-nav .nav .nav-link>span.sidenav-normal {
    transition: all 0.1s ease 0s;
  }

  .navbar-vertical.navbar-expand-md .navbar-nav .nav .nav-link.active {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}

@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 15.625rem !important;
    overflow-y: auto;
    padding: 0;
    box-shadow: none;
  }

  .navbar-vertical.navbar-expand-lg .navbar-collapse {
    display: block;
    overflow: auto;
    height: calc(100vh - 200px);
  }

  .navbar-vertical.navbar-expand-lg>[class*="container"] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

@media all and (min-width: 992px) and (-ms-high-contrast: none),
(min-width: 992px) and (-ms-high-contrast: active) {
  .navbar-vertical.navbar-expand-lg>[class*="container"] {
    min-height: none;
    height: 100%;
  }
}

@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg.fixed-start {
    left: 0;
  }

  .navbar-vertical.navbar-expand-lg.fixed-end {
    right: 0;
  }

  .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    margin: 0 1rem;
    margin-bottom: 1.5px;
  }

  .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link .nav-link-text,
  .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link .sidenav-mini-icon,
  .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link .sidenav-normal,
  .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link i {
    pointer-events: none;
  }

  .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item {
    width: 100%;
  }

  .navbar-vertical.navbar-expand-lg .navbar-nav>.nav-item {
    margin-top: 0.125rem;
  }

  .navbar-vertical.navbar-expand-lg .navbar-nav>.nav-item .icon .ni {
    top: 0;
  }

  .navbar-vertical.navbar-expand-lg .lavalamp-object {
    width: calc(100% - 1rem) !important;
    background: theme-color("primary");
    color: color-yiq(#e91e63);
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.125rem;
  }

  .navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav-link {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 15px;
  }

  .navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav-link>span.sidenav-normal {
    transition: all 0.1s ease 0s;
  }

  .navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav-link.active {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}

@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 15.625rem !important;
    overflow-y: auto;
    padding: 0;
    box-shadow: none;
  }

  .navbar-vertical.navbar-expand-xl .navbar-collapse {
    display: block;
    overflow: auto;
    height: calc(100vh - 200px);
  }

  .navbar-vertical.navbar-expand-xl>[class*="container"] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

@media all and (min-width: 1200px) and (-ms-high-contrast: none),
(min-width: 1200px) and (-ms-high-contrast: active) {
  .navbar-vertical.navbar-expand-xl>[class*="container"] {
    min-height: none;
    height: 100%;
  }
}

@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl.fixed-start {
    left: 0;
  }

  .navbar-vertical.navbar-expand-xl.fixed-end {
    right: 0;
  }

  .navbar-vertical.navbar-expand-xl .navbar-nav .nav-link {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    margin: 0 1rem;
    margin-bottom: 1.5px;
  }

  .navbar-vertical.navbar-expand-xl .navbar-nav .nav-link .nav-link-text,
  .navbar-vertical.navbar-expand-xl .navbar-nav .nav-link .sidenav-mini-icon,
  .navbar-vertical.navbar-expand-xl .navbar-nav .nav-link .sidenav-normal,
  .navbar-vertical.navbar-expand-xl .navbar-nav .nav-link i {
    pointer-events: none;
  }

  .navbar-vertical.navbar-expand-xl .navbar-nav .nav-item {
    width: 100%;
  }

  .navbar-vertical.navbar-expand-xl .navbar-nav>.nav-item {
    margin-top: 0.125rem;
  }

  .navbar-vertical.navbar-expand-xl .navbar-nav>.nav-item .icon .ni {
    top: 0;
  }

  .navbar-vertical.navbar-expand-xl .lavalamp-object {
    width: calc(100% - 1rem) !important;
    background: theme-color("primary");
    color: color-yiq(#e91e63);
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.125rem;
  }

  .navbar-vertical.navbar-expand-xl .navbar-nav .nav .nav-link {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 15px;
  }

  .navbar-vertical.navbar-expand-xl .navbar-nav .nav .nav-link>span.sidenav-normal {
    transition: all 0.1s ease 0s;
  }

  .navbar-vertical.navbar-expand-xl .navbar-nav .nav .nav-link.active {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}

@media (min-width: 1400px) {
  .navbar-vertical.navbar-expand-xxl {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 15.625rem !important;
    overflow-y: auto;
    padding: 0;
    box-shadow: none;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-collapse {
    display: block;
    overflow: auto;
    height: calc(100vh - 200px);
  }

  .navbar-vertical.navbar-expand-xxl>[class*="container"] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

@media all and (min-width: 1400px) and (-ms-high-contrast: none),
(min-width: 1400px) and (-ms-high-contrast: active) {
  .navbar-vertical.navbar-expand-xxl>[class*="container"] {
    min-height: none;
    height: 100%;
  }
}

@media (min-width: 1400px) {
  .navbar-vertical.navbar-expand-xxl.fixed-start {
    left: 0;
  }

  .navbar-vertical.navbar-expand-xxl.fixed-end {
    right: 0;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-nav .nav-link {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    margin: 0 1rem;
    margin-bottom: 1.5px;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-nav .nav-link .nav-link-text,
  .navbar-vertical.navbar-expand-xxl .navbar-nav .nav-link .sidenav-mini-icon,
  .navbar-vertical.navbar-expand-xxl .navbar-nav .nav-link .sidenav-normal,
  .navbar-vertical.navbar-expand-xxl .navbar-nav .nav-link i {
    pointer-events: none;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-nav .nav-item {
    width: 100%;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-nav>.nav-item {
    margin-top: 0.125rem;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-nav>.nav-item .icon .ni {
    top: 0;
  }

  .navbar-vertical.navbar-expand-xxl .lavalamp-object {
    width: calc(100% - 1rem) !important;
    background: theme-color("primary");
    color: color-yiq(#e91e63);
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.125rem;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-nav .nav .nav-link {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 15px;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-nav .nav .nav-link>span.sidenav-normal {
    transition: all 0.1s ease 0s;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-nav .nav .nav-link.active {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}

.sidenav[data-color="primary"] .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item.active .nav-link.active,
.sidenav[data-color="primary"] .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item.active .nav-link.active {
  background-image: linear-gradient(195deg, #e91e63 0%, #e91e63 100%);
}

.sidenav[data-color="primary"] .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item .nav-link.active,
.sidenav[data-color="primary"] .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item .nav-link.active {
  background-color: rgba(199, 199, 199, 0.2);
}

.sidenav[data-color="primary"] .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item .nav-link.active+.collapse .nav-item .nav-link.active,
.sidenav[data-color="primary"] .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item .nav-link.active+.collapse .nav-item .nav-link.active {
  background-image: linear-gradient(195deg, #e91e63 0%, #e91e63 100%);
}

.sidenav[data-color="secondary"] .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item.active .nav-link.active,
.sidenav[data-color="secondary"] .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item.active .nav-link.active {
  background-image: linear-gradient(195deg, #7b809a 0%, #7b809a 100%);
}

.sidenav[data-color="secondary"] .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item .nav-link.active,
.sidenav[data-color="secondary"] .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item .nav-link.active {
  background-color: rgba(199, 199, 199, 0.2);
}

.sidenav[data-color="secondary"] .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item .nav-link.active+.collapse .nav-item .nav-link.active,
.sidenav[data-color="secondary"] .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item .nav-link.active+.collapse .nav-item .nav-link.active {
  background-image: linear-gradient(195deg, #7b809a 0%, #7b809a 100%);
}

.sidenav[data-color="success"] .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item.active .nav-link.active,
.sidenav[data-color="success"] .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item.active .nav-link.active {
  background-image: linear-gradient(195deg, #4CAF50 0%, #4CAF50 100%);
}

.sidenav[data-color="success"] .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item .nav-link.active,
.sidenav[data-color="success"] .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item .nav-link.active {
  background-color: rgba(199, 199, 199, 0.2);
}

.sidenav[data-color="success"] .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item .nav-link.active+.collapse .nav-item .nav-link.active,
.sidenav[data-color="success"] .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item .nav-link.active+.collapse .nav-item .nav-link.active {
  background-image: linear-gradient(195deg, #4CAF50 0%, #4CAF50 100%);
}

.sidenav[data-color="info"] .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item.active .nav-link.active,
.sidenav[data-color="info"] .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item.active .nav-link.active {
  background-image: linear-gradient(195deg, #1A73E8 0%, #1A73E8 100%);
}

.sidenav[data-color="info"] .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item .nav-link.active,
.sidenav[data-color="info"] .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item .nav-link.active {
  background-color: rgba(199, 199, 199, 0.2);
}

.sidenav[data-color="info"] .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item .nav-link.active+.collapse .nav-item .nav-link.active,
.sidenav[data-color="info"] .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item .nav-link.active+.collapse .nav-item .nav-link.active {
  background-image: linear-gradient(195deg, #1A73E8 0%, #1A73E8 100%);
}

.sidenav[data-color="warning"] .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item.active .nav-link.active,
.sidenav[data-color="warning"] .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item.active .nav-link.active {
  background-image: linear-gradient(195deg, #fb8c00 0%, #fb8c00 100%);
}

.sidenav[data-color="warning"] .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item .nav-link.active,
.sidenav[data-color="warning"] .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item .nav-link.active {
  background-color: rgba(199, 199, 199, 0.2);
}

.sidenav[data-color="warning"] .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item .nav-link.active+.collapse .nav-item .nav-link.active,
.sidenav[data-color="warning"] .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item .nav-link.active+.collapse .nav-item .nav-link.active {
  background-image: linear-gradient(195deg, #fb8c00 0%, #fb8c00 100%);
}

.sidenav[data-color="danger"] .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item.active .nav-link.active,
.sidenav[data-color="danger"] .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item.active .nav-link.active {
  background-image: linear-gradient(195deg, #F44335 0%, #F44335 100%);
}

.sidenav[data-color="danger"] .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item .nav-link.active,
.sidenav[data-color="danger"] .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item .nav-link.active {
  background-color: rgba(199, 199, 199, 0.2);
}

.sidenav[data-color="danger"] .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item .nav-link.active+.collapse .nav-item .nav-link.active,
.sidenav[data-color="danger"] .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item .nav-link.active+.collapse .nav-item .nav-link.active {
  background-image: linear-gradient(195deg, #F44335 0%, #F44335 100%);
}

.sidenav[data-color="light"] .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item.active .nav-link.active,
.sidenav[data-color="light"] .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item.active .nav-link.active {
  background-image: linear-gradient(195deg, #f0f2f5 0%, #f0f2f5 100%);
}

.sidenav[data-color="light"] .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item .nav-link.active,
.sidenav[data-color="light"] .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item .nav-link.active {
  background-color: rgba(199, 199, 199, 0.2);
}

.sidenav[data-color="light"] .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item .nav-link.active+.collapse .nav-item .nav-link.active,
.sidenav[data-color="light"] .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item .nav-link.active+.collapse .nav-item .nav-link.active {
  background-image: linear-gradient(195deg, #f0f2f5 0%, #f0f2f5 100%);
}

.sidenav[data-color="dark"] .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item.active .nav-link.active,
.sidenav[data-color="dark"] .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item.active .nav-link.active {
  background-image: linear-gradient(195deg, #344767 0%, #344767 100%);
}

.sidenav[data-color="dark"] .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item .nav-link.active,
.sidenav[data-color="dark"] .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item .nav-link.active {
  background-color: rgba(199, 199, 199, 0.2);
}

.sidenav[data-color="dark"] .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item .nav-link.active+.collapse .nav-item .nav-link.active,
.sidenav[data-color="dark"] .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item .nav-link.active+.collapse .nav-item .nav-link.active {
  background-image: linear-gradient(195deg, #344767 0%, #344767 100%);
}

.sidenav[data-color="white"] .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item.active .nav-link.active,
.sidenav[data-color="white"] .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item.active .nav-link.active {
  background-image: linear-gradient(195deg, #fff 0%, #fff 100%);
}

.sidenav[data-color="white"] .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item .nav-link.active,
.sidenav[data-color="white"] .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item .nav-link.active {
  background-color: rgba(199, 199, 199, 0.2);
}

.sidenav[data-color="white"] .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item .nav-link.active+.collapse .nav-item .nav-link.active,
.sidenav[data-color="white"] .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item .nav-link.active+.collapse .nav-item .nav-link.active {
  background-image: linear-gradient(195deg, #fff 0%, #fff 100%);
}

.sidenav[data-color="dark-blue"] .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item.active .nav-link.active,
.sidenav[data-color="dark-blue"] .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item.active .nav-link.active {
  background-image: linear-gradient(195deg, #1A237E 0%, #1A237E 100%);
}

.sidenav[data-color="dark-blue"] .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item .nav-link.active,
.sidenav[data-color="dark-blue"] .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item .nav-link.active {
  background-color: rgba(199, 199, 199, 0.2);
}

.sidenav[data-color="dark-blue"] .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item .nav-link.active+.collapse .nav-item .nav-link.active,
.sidenav[data-color="dark-blue"] .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item .nav-link.active+.collapse .nav-item .nav-link.active {
  background-image: linear-gradient(195deg, #1A237E 0%, #1A237E 100%);
}

.main-content,
.sidenav {
  transition: all 0.2s ease-in-out;
}

.sidenav {
  z-index: 9999;
}

.sidenav .navbar-brand,
.sidenav .navbar-heading {
  display: block;
}

@media (min-width: 1200px) {
  .sidenav:hover {
    max-width: 15.625rem;
  }

  .sidenav .sidenav-toggler {
    padding: 1.5rem;
  }

  .sidenav.fixed-start+.main-content {
    margin-left: 17.125rem;
  }

  .sidenav.fixed-end+.main-content {
    margin-right: 17.125rem;
  }
}

.sidenav .navbar-heading .docs-mini {
  padding-left: 3px;
}

.sidenav .navbar-heading {
  transition: all 0.1s ease;
}

.sidenav .navbar-brand {
  padding: 1.5rem 2rem;
}

.sidenav .collapse .nav-item .nav-link.active {
  color: #fff !important;
}

.sidenav .collapse .nav-item .nav-link.active i {
  color: #fff !important;
}

body:not(.dark-version) .sidenav.bg-white .collapse .nav-item .nav-link.active.text-dark i,
body:not(.dark-version) .sidenav.bg-white .collapse .nav-item .nav-link.active.text-dark .nav-link-text,
body:not(.dark-version) .sidenav.bg-transparent .collapse .nav-item .nav-link.active.text-dark i,
body:not(.dark-version) .sidenav.bg-transparent .collapse .nav-item .nav-link.active.text-dark .nav-link-text {
  color: #344767 !important;
}

.sidenav-header {
  height: 4.875rem;
}

.sidenav-footer .card.card-background:after {
  opacity: 0.65;
}

.g-sidenav-show .sidenav .nav-item .collapse {
  height: auto;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .g-sidenav-show .sidenav .nav-item .collapse {
    transition: none;
  }
}

.g-sidenav-show .sidenav .nav-link-text {
  transition: 0.3s ease;
  opacity: 1;
}

.g-sidenav-show.rtl .navbar-vertical .navbar-nav .nav-link[data-bs-toggle="collapse"]:after {
  margin-left: 0;
}

@media (max-width: 1199.98px) {
  .g-sidenav-show.rtl .sidenav {
    transform: translateX(17.125rem);
  }

  .g-sidenav-show:not(.rtl) .sidenav {
    transform: translateX(-17.125rem);
  }

  .g-sidenav-show .sidenav.fixed-start+.main-content {
    margin-left: 0 !important;
  }

  .g-sidenav-show.g-sidenav-pinned .sidenav {
    transform: translateX(0);
  }
}

.navbar-vertical.bg-white {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.navbar-vertical.bg-white .navbar-nav .nav-link.active {
  box-shadow: none;
}

.navbar-vertical.bg-transparent .navbar-nav .nav-link.active:after,
.navbar-vertical.bg-white .navbar-nav .nav-link.active:after {
  color: rgba(206, 212, 218, 0.7) !important;
}

.navbar-vertical .navbar-nav .nav-link.active {
  font-weight: 400;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 0.375rem;
  margin-top: 1.5px;
  margin-bottom: 1.5px;
}

.navbar-vertical .navbar-nav>.nav-item .nav-link.active {
  color: #fff;
  border-right-width: 0;
  border-bottom-width: 0;
  background-color: rgba(199, 199, 199, 0.2);
}

.navbar-vertical .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item.active .nav-link.active,
.navbar-vertical .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item.active .nav-link.active {
  background-image: linear-gradient(195deg, #e91e63 0%, #e91e63 100%);
}

.navbar-vertical .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item.active .nav-link.active span,
.navbar-vertical .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item.active .nav-link.active span {
  color: #fff;
}

.navbar-vertical .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item .nav-link.active,
.navbar-vertical .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item .nav-link.active {
  background-color: rgba(199, 199, 199, 0.2);
}

.navbar-vertical .navbar-nav>.nav-item .nav-link.active+.collapse .nav-item .nav-link.active+.collapse .nav-item .nav-link.active,
.navbar-vertical .navbar-nav>.nav-item .nav-link.active+.collapsing .nav-item .nav-link.active+.collapse .nav-item .nav-link.active {
  background-image: linear-gradient(195deg, #e91e63 0%, #e91e63 100%);
}

.navbar-main {
  transition: box-shadow 0.25s ease-in, background-color 0.25s ease-in;
}

.navbar-main.fixed-top {
  width: calc(100% - (15.625rem + 1.5rem * 3));
}

.navbar-main.fixed-top+[class*="container"] {
  margin-top: 7.1875rem !important;
}

.navbar-vertical .navbar-nav .nav-link[data-bs-toggle="collapse"]:after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: 'Font Awesome 5 Free';
  font-weight: 700;
  content: "\f107";
  margin-left: auto;
  color: rgba(206, 212, 218, 0.7);
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-vertical .navbar-nav .nav-link[data-bs-toggle="collapse"]:after {
    transition: none;
  }
}

.navbar-vertical .navbar-nav .nav-link[data-bs-toggle="collapse"][aria-expanded="true"]:after {
  color: #CED4DA;
  transform: rotate(180deg);
}

.navbar-vertical .navbar-nav .nav-link[data-bs-toggle="collapse"].active:after {
  color: #fff;
}

.navbar-vertical .navbar-nav .nav-item .collapse .nav,
.navbar-vertical .navbar-nav .nav-item .collapsing .nav {
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {

  .navbar-vertical .navbar-nav .nav-item .collapse .nav,
  .navbar-vertical .navbar-nav .nav-item .collapsing .nav {
    transition: none;
  }
}

.navbar-vertical .navbar-nav .nav-item .collapse .nav .nav-item .nav-link,
.navbar-vertical .navbar-nav .nav-item .collapsing .nav .nav-item .nav-link {
  position: relative;
  background-color: transparent;
  box-shadow: none;
  color: rgba(206, 212, 218, 0.7);
}

.navbar-vertical .navbar-nav .nav-item .collapse .nav .nav-item .nav-link.active,
.navbar-vertical .navbar-nav .nav-item .collapsing .nav .nav-item .nav-link.active {
  color: #CED4DA;
}

.navbar-vertical .navbar-nav .nav-item .collapse .nav .nav-item.active .nav-link,
.navbar-vertical .navbar-nav .nav-item .collapsing .nav .nav-item.active .nav-link {
  color: #CED4DA;
}

.navbar-vertical.blur .navbar-nav>.nav-item .nav-link {
  background-color: transparent;
  box-shadow: none;
}

.navbar-vertical .navbar-brand .navbar-brand-img,
.navbar-vertical .navbar-brand span {
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {

  .navbar-vertical .navbar-brand .navbar-brand-img,
  .navbar-vertical .navbar-brand span {
    transition: none;
  }
}

.navbar-vertical .nav-item .nav-link span.sidenav-mini-icon {
  transition: all 0.2s ease-in-out;
  text-align: center;
  min-width: 1.8rem;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-vertical .nav-item .nav-link span.sidenav-mini-icon {
    transition: none;
  }
}

.navbar-vertical .docs-info {
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-vertical .docs-info {
    transition: none;
  }
}

.navbar-vertical .nav-item .nav-link {
  margin-top: 3px;
  margin-bottom: 3px;
  border-radius: 0.375rem;
  margin-bottom: 1.5px;
  margin-top: 1.5px;
}

.navbar-vertical .nav-item .nav-link+.collapse .nav .nav-item>.nav-link,
.navbar-vertical .nav-item .nav-link+.collapsing .nav .nav-item>.nav-link {
  margin-top: 1.5px;
  margin-bottom: 1.5px;
}

.navbar-vertical .nav-item .nav-link+.collapse .nav .nav-item>.nav-link+.collapse .nav .nav-item .nav-link,
.navbar-vertical .nav-item .nav-link+.collapse .nav .nav-item>.nav-link+.collapsing .nav .nav-item .nav-link,
.navbar-vertical .nav-item .nav-link+.collapsing .nav .nav-item>.nav-link+.collapse .nav .nav-item .nav-link,
.navbar-vertical .nav-item .nav-link+.collapsing .nav .nav-item>.nav-link+.collapsing .nav .nav-item .nav-link {
  margin-top: 1.5px;
  margin-bottom: 1.5px;
}

.navbar-vertical .nav-item:hover .nav-link {
  background-color: rgba(199, 199, 199, 0.2);
  border-radius: 0.375rem;
}

.navbar-vertical .nav-item:hover .nav-link+.collapse .nav .nav-item:hover>.nav-link {
  background-color: rgba(199, 199, 199, 0.2);
  border-radius: 0.375rem;
}

.navbar-vertical .nav-item:hover .nav-link+.collapse .nav .nav-item+.collapse .nav .nav-item:hover .nav-link {
  background-color: rgba(199, 199, 199, 0.2);
  border-radius: 0.375rem;
}

@media (min-width: 1200px) {
  .g-sidenav-hidden.rtl .main-content {
    margin-right: 6rem !important;
  }

  .g-sidenav-hidden.rtl .navbar-vertical:hover {
    max-width: 15.625rem !important;
  }

  .g-sidenav-hidden.rtl .navbar-vertical .nav-item .nav-link .material-icons-round {
    margin-right: 2px;
  }

  .g-sidenav-hidden.rtl .sidenav:hover+.main-content {
    margin-right: 17.125rem !important;
  }

  .g-sidenav-hidden .navbar-vertical {
    max-width: 6rem !important;
  }

  .g-sidenav-hidden .navbar-vertical.fixed-start+.main-content {
    margin-left: 7.5rem;
  }

  .g-sidenav-hidden .navbar-vertical .navbar-brand img {
    width: auto !important;
  }

  .g-sidenav-hidden .navbar-vertical .navbar-brand span {
    opacity: 0;
  }

  .g-sidenav-hidden .navbar-vertical .nav-item .nav-link .icon {
    padding: 10px;
  }

  .g-sidenav-hidden .navbar-vertical .nav-item .nav-link .material-icons-round {
    margin-left: 2px;
  }

  .g-sidenav-hidden .navbar-vertical .nav-item .nav-link .nav-link-text,
  .g-sidenav-hidden .navbar-vertical .nav-item .nav-link .sidenav-normal {
    opacity: 0;
    width: 0;
  }

  .g-sidenav-hidden .navbar-vertical .nav-item .nav-link .sidenav-mini-icon {
    min-width: 1.8rem;
    margin-left: 0.15rem !important;
  }

  .g-sidenav-hidden .navbar-vertical .nav-item .nav-link[data-bs-toggle="collapse"]:after {
    content: "";
    opacity: 0;
  }

  .g-sidenav-hidden .navbar-vertical .nav-item .collapse .nav {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }

  .g-sidenav-hidden .navbar-vertical .nav-item .collapse .nav .nav-item .nav-link {
    margin-left: 1rem;
  }

  .g-sidenav-hidden .navbar-vertical .nav-item .collapse .nav .nav-item .nav-link[data-bs-toggle="collapse"]:after {
    content: "\f107";
  }

  .g-sidenav-hidden .navbar-vertical .card.card-background .icon-shape {
    margin-bottom: 0 !important;
  }

  .g-sidenav-hidden .navbar-vertical .card.card-background .docs-info {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .g-sidenav-hidden .navbar-vertical:hover {
    max-width: 15.625rem !important;
  }

  .g-sidenav-hidden .navbar-vertical:hover.fixed-start+.main-content {
    margin-left: 17.125rem;
  }

  .g-sidenav-hidden .navbar-vertical:hover .navbar-brand span {
    opacity: 1;
  }

  .g-sidenav-hidden .navbar-vertical:hover .nav-item .nav-link .nav-link-text,
  .g-sidenav-hidden .navbar-vertical:hover .nav-item .nav-link .sidenav-normal {
    opacity: 1;
    width: auto;
  }

  .g-sidenav-hidden .navbar-vertical:hover .nav-item .nav-link[data-bs-toggle="collapse"]:after {
    content: "\f107";
    opacity: 1;
  }

  .g-sidenav-hidden .navbar-vertical:hover .nav-item .collapse .nav .nav-item .collapse .nav,
  .g-sidenav-hidden .navbar-vertical:hover .nav-item .collapse .nav .nav-item .collapsing .nav {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }

  .g-sidenav-hidden .navbar-vertical:hover .card.card-background .icon-shape {
    margin-bottom: 1rem !important;
  }

  .g-sidenav-hidden .navbar-vertical:hover .card.card-background .docs-info {
    opacity: 1;
    width: auto;
    height: auto;
  }
}

.nav.nav-pills {
  background: #f8f9fa;
  border-radius: 0.75rem;
  position: relative;
}

.nav.nav-pills.nav-pills-vertical {
  border-radius: 1.1875rem;
}

.nav.nav-pills.nav-pills-vertical .nav-link.active {
  border-radius: 0.875rem;
}

.nav.nav-pills .nav-link {
  z-index: 3;
  color: #344767;
  border-radius: 0.5rem;
  background-color: inherit;
}

.nav.nav-pills .nav-link.active {
  animation: 0.2s ease;
}

.nav.nav-pills .nav-link:hover:not(.active) {
  color: #344767;
}

.nav.nav-pills.nav-pills-primary {
  background: #fff;
  color: #fff;
}

.nav.nav-pills.nav-pills-primary .nav-link.active {
  color: #fff;
}

.nav.nav-pills.nav-pills-primary .moving-tab .nav-link.active {
  background: #EC407A;
  color: #EC407A;
}

.nav.nav-pills.nav-pills-info {
  background: #fff;
  color: #fff;
}

.nav.nav-pills.nav-pills-info .nav-link.active {
  color: #fff;
}

.nav.nav-pills.nav-pills-info .moving-tab .nav-link.active {
  background: #49a3f1;
  color: #49a3f1;
}

.nav.nav-pills.nav-pills-success {
  background: #fff;
  color: #fff;
}

.nav.nav-pills.nav-pills-success .nav-link.active {
  color: #fff;
}

.nav.nav-pills.nav-pills-success .moving-tab .nav-link.active {
  background: #66BB6A;
  color: #66BB6A;
}

.nav.nav-pills.nav-pills-warning {
  background: #fff;
  color: #fff;
}

.nav.nav-pills.nav-pills-warning .nav-link.active {
  color: #fff;
}

.nav.nav-pills.nav-pills-warning .moving-tab .nav-link.active {
  background: #FFA726;
  color: #FFA726;
}

.nav.nav-pills.nav-pills-danger {
  background: #fff;
  color: #fff;
}

.nav.nav-pills.nav-pills-danger .nav-link.active {
  color: #fff;
}

.nav.nav-pills.nav-pills-danger .moving-tab .nav-link.active {
  background: #EF5350;
  color: #EF5350;
}

.nav.nav-pills .nav-item {
  z-index: 3;
}

.moving-tab {
  z-index: 1 !important;
}

.moving-tab .nav-link {
  color: #fff;
  transition: .2s ease;
  border-radius: 0.5rem;
}

.moving-tab .nav-link.active {
  color: #fff;
  font-weight: 600;
  box-shadow: 0px 1px 5px 1px #ddd;
  animation: 0.2s ease;
  background: #fff;
}

.moving-tab .nav-link:hover:not(.active) {
  color: #344767;
}

.page-item.active .page-link {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.09), 0 2px 3px -1px rgba(0, 0, 0, 0.07);
}

.page-item .page-link,
.page-item span {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7b809a;
  padding: 0;
  margin: 0 3px;
  border-radius: 50% !important;
  width: 36px;
  height: 36px;
  font-size: 0.875rem;
}

.pagination-lg .page-item .page-link,
.pagination-lg .page-item span {
  width: 46px;
  height: 46px;
  line-height: 46px;
}

.pagination-sm .page-item .page-link,
.pagination-sm .page-item span {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.pagination.pagination-primary .page-item.active>.page-link,
.pagination.pagination-primary .page-item.active>.page-link:focus,
.pagination.pagination-primary .page-item.active>.page-link:hover {
  background-image: linear-gradient(195deg, #EC407A 0%, #D81B60 100%);
  border: none;
}

.pagination.pagination-secondary .page-item.active>.page-link,
.pagination.pagination-secondary .page-item.active>.page-link:focus,
.pagination.pagination-secondary .page-item.active>.page-link:hover {
  background-image: linear-gradient(195deg, #747b8a 0%, #495361 100%);
  border: none;
}

.pagination.pagination-success .page-item.active>.page-link,
.pagination.pagination-success .page-item.active>.page-link:focus,
.pagination.pagination-success .page-item.active>.page-link:hover {
  background-image: linear-gradient(195deg, #66BB6A 0%, #43A047 100%);
  border: none;
}

.pagination.pagination-info .page-item.active>.page-link,
.pagination.pagination-info .page-item.active>.page-link:focus,
.pagination.pagination-info .page-item.active>.page-link:hover {
  background-image: linear-gradient(195deg, #49a3f1 0%, #1A73E8 100%);
  border: none;
}

.pagination.pagination-warning .page-item.active>.page-link,
.pagination.pagination-warning .page-item.active>.page-link:focus,
.pagination.pagination-warning .page-item.active>.page-link:hover {
  background-image: linear-gradient(195deg, #FFA726 0%, #FB8C00 100%);
  border: none;
}

.pagination.pagination-danger .page-item.active>.page-link,
.pagination.pagination-danger .page-item.active>.page-link:focus,
.pagination.pagination-danger .page-item.active>.page-link:hover {
  background-image: linear-gradient(195deg, #EF5350 0%, #E53935 100%);
  border: none;
}

.pagination.pagination-light .page-item.active>.page-link,
.pagination.pagination-light .page-item.active>.page-link:focus,
.pagination.pagination-light .page-item.active>.page-link:hover {
  background-image: linear-gradient(195deg, #EBEFF4 0%, #CED4DA 100%);
  border: none;
}

.pagination.pagination-dark .page-item.active>.page-link,
.pagination.pagination-dark .page-item.active>.page-link:focus,
.pagination.pagination-dark .page-item.active>.page-link:hover {
  background-image: linear-gradient(195deg, #42424a 0%, #191919 100%);
  border: none;
}

.popover {
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.12);
}

.popover .popover-header {
  font-weight: 600;
}

.progress-bar {
  height: 6px;
  border-radius: 0.125rem;
}

.progress {
  overflow: visible;
}

.progress.progress-sm {
  height: 4px;
}

.progress.progress-lg {
  height: 20px;
}

.rtl .breadcrumb .breadcrumb-item+.breadcrumb-item::before {
  float: right;
  padding-left: 0.5rem;
  padding-right: 0;
}

.rtl .sidenav .navbar-nav {
  width: 100%;
  padding-right: 0;
}

.rtl .fixed-plugin .fixed-plugin-button {
  left: 30px;
  right: auto;
}

.rtl .fixed-plugin .card {
  left: -360px !important;
  right: auto;
}

.rtl .fixed-plugin.show .card {
  right: auto;
  left: 0 !important;
}

.rtl .timeline .timeline-content {
  margin-right: 45px;
  margin-left: 0;
}

.rtl .timeline .timeline-step {
  transform: translateX(50%);
}

.rtl .timeline.timeline-one-side:before {
  right: 1rem;
}

.rtl .timeline.timeline-one-side .timeline-step {
  right: 1rem;
}

.rtl .form-check.form-switch .form-check-input:after {
  transform: translateX(-1px);
}

.rtl .form-check.form-switch .form-check-input:checked:after {
  transform: translateX(21px);
}

.rtl .avatar-group .avatar+.avatar {
  margin-left: 0;
  margin-right: -1rem;
}

.rtl .dropdown .dropdown-menu {
  left: 0;
}

.rtl .input-group.input-group-outline .form-label:before {
  margin-left: 4px;
  margin-right: 0;
  border-radius: 0 4px;
}

.rtl .input-group.input-group-outline .form-label:after {
  margin-left: 0;
  margin-right: 4px;
  border-radius: 5px 0;
}

.rtl .input-group.input-group-outline.is-valid .form-control,
.rtl .input-group.input-group-outline.is-invalid .form-control {
  background-position: left 0.75rem center !important;
}

.rtl .input-group .input-group-text {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rtl .input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-right: -1px;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.rtl .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n + 3),
.rtl .input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.ripple {
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  transform: scale(0);
  animation: ripple 0.65s linear;
}

@keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}

.btn.btn-facebook {
  background-color: #3b5998;
  color: #fff;
}

.btn.btn-facebook:focus,
.btn.btn-facebook:hover {
  background-color: #344e86;
  color: #fff;
}

.btn.btn-facebook:active,
.btn.btn-facebook:focus,
.btn.btn-facebook:active:focus {
  box-shadow: none;
}

.btn.btn-facebook.btn-simple {
  color: #344e86;
  background-color: transparent;
  background-image: none !important;
  box-shadow: none;
  border: none;
}

.btn.btn-facebook.btn-simple:hover,
.btn.btn-facebook.btn-simple:focus,
.btn.btn-facebook.btn-simple:hover:focus,
.btn.btn-facebook.btn-simple:active,
.btn.btn-facebook.btn-simple:hover:focus:active {
  color: #344e86;
  background: transparent !important;
  box-shadow: none !important;
}

.btn.btn-facebook.btn-neutral {
  color: #3b5998;
  background-color: #fff;
}

.btn.btn-facebook.btn-neutral:hover,
.btn.btn-facebook.btn-neutral:focus,
.btn.btn-facebook.btn-neutral:active {
  color: #344e86;
}

.btn.btn-twitter {
  background-color: #55acee;
  color: #fff;
}

.btn.btn-twitter:focus,
.btn.btn-twitter:hover {
  background-color: #3ea1ec;
  color: #fff;
}

.btn.btn-twitter:active,
.btn.btn-twitter:focus,
.btn.btn-twitter:active:focus {
  box-shadow: none;
}

.btn.btn-twitter.btn-simple {
  color: #3ea1ec;
  background-color: transparent;
  background-image: none !important;
  box-shadow: none;
  border: none;
}

.btn.btn-twitter.btn-simple:hover,
.btn.btn-twitter.btn-simple:focus,
.btn.btn-twitter.btn-simple:hover:focus,
.btn.btn-twitter.btn-simple:active,
.btn.btn-twitter.btn-simple:hover:focus:active {
  color: #3ea1ec;
  background: transparent !important;
  box-shadow: none !important;
}

.btn.btn-twitter.btn-neutral {
  color: #55acee;
  background-color: #fff;
}

.btn.btn-twitter.btn-neutral:hover,
.btn.btn-twitter.btn-neutral:focus,
.btn.btn-twitter.btn-neutral:active {
  color: #3ea1ec;
}

.btn.btn-pinterest {
  background-color: #cc2127;
  color: #fff;
}

.btn.btn-pinterest:focus,
.btn.btn-pinterest:hover {
  background-color: #b21d22;
  color: #fff;
}

.btn.btn-pinterest:active,
.btn.btn-pinterest:focus,
.btn.btn-pinterest:active:focus {
  box-shadow: none;
}

.btn.btn-pinterest.btn-simple {
  color: #b21d22;
  background-color: transparent;
  background-image: none !important;
  box-shadow: none;
  border: none;
}

.btn.btn-pinterest.btn-simple:hover,
.btn.btn-pinterest.btn-simple:focus,
.btn.btn-pinterest.btn-simple:hover:focus,
.btn.btn-pinterest.btn-simple:active,
.btn.btn-pinterest.btn-simple:hover:focus:active {
  color: #b21d22;
  background: transparent !important;
  box-shadow: none !important;
}

.btn.btn-pinterest.btn-neutral {
  color: #cc2127;
  background-color: #fff;
}

.btn.btn-pinterest.btn-neutral:hover,
.btn.btn-pinterest.btn-neutral:focus,
.btn.btn-pinterest.btn-neutral:active {
  color: #b21d22;
}

.btn.btn-linkedin {
  background-color: #0077B5;
  color: #fff;
}

.btn.btn-linkedin:focus,
.btn.btn-linkedin:hover {
  background-color: #00669c;
  color: #fff;
}

.btn.btn-linkedin:active,
.btn.btn-linkedin:focus,
.btn.btn-linkedin:active:focus {
  box-shadow: none;
}

.btn.btn-linkedin.btn-simple {
  color: #00669c;
  background-color: transparent;
  background-image: none !important;
  box-shadow: none;
  border: none;
}

.btn.btn-linkedin.btn-simple:hover,
.btn.btn-linkedin.btn-simple:focus,
.btn.btn-linkedin.btn-simple:hover:focus,
.btn.btn-linkedin.btn-simple:active,
.btn.btn-linkedin.btn-simple:hover:focus:active {
  color: #00669c;
  background: transparent !important;
  box-shadow: none !important;
}

.btn.btn-linkedin.btn-neutral {
  color: #0077B5;
  background-color: #fff;
}

.btn.btn-linkedin.btn-neutral:hover,
.btn.btn-linkedin.btn-neutral:focus,
.btn.btn-linkedin.btn-neutral:active {
  color: #00669c;
}

.btn.btn-dribbble {
  background-color: #ea4c89;
  color: #fff;
}

.btn.btn-dribbble:focus,
.btn.btn-dribbble:hover {
  background-color: #e73177;
  color: #fff;
}

.btn.btn-dribbble:active,
.btn.btn-dribbble:focus,
.btn.btn-dribbble:active:focus {
  box-shadow: none;
}

.btn.btn-dribbble.btn-simple {
  color: #e73177;
  background-color: transparent;
  background-image: none !important;
  box-shadow: none;
  border: none;
}

.btn.btn-dribbble.btn-simple:hover,
.btn.btn-dribbble.btn-simple:focus,
.btn.btn-dribbble.btn-simple:hover:focus,
.btn.btn-dribbble.btn-simple:active,
.btn.btn-dribbble.btn-simple:hover:focus:active {
  color: #e73177;
  background: transparent !important;
  box-shadow: none !important;
}

.btn.btn-dribbble.btn-neutral {
  color: #ea4c89;
  background-color: #fff;
}

.btn.btn-dribbble.btn-neutral:hover,
.btn.btn-dribbble.btn-neutral:focus,
.btn.btn-dribbble.btn-neutral:active {
  color: #e73177;
}

.btn.btn-github {
  background-color: #24292E;
  color: #fff;
}

.btn.btn-github:focus,
.btn.btn-github:hover {
  background-color: #171a1d;
  color: #fff;
}

.btn.btn-github:active,
.btn.btn-github:focus,
.btn.btn-github:active:focus {
  box-shadow: none;
}

.btn.btn-github.btn-simple {
  color: #171a1d;
  background-color: transparent;
  background-image: none !important;
  box-shadow: none;
  border: none;
}

.btn.btn-github.btn-simple:hover,
.btn.btn-github.btn-simple:focus,
.btn.btn-github.btn-simple:hover:focus,
.btn.btn-github.btn-simple:active,
.btn.btn-github.btn-simple:hover:focus:active {
  color: #171a1d;
  background: transparent !important;
  box-shadow: none !important;
}

.btn.btn-github.btn-neutral {
  color: #24292E;
  background-color: #fff;
}

.btn.btn-github.btn-neutral:hover,
.btn.btn-github.btn-neutral:focus,
.btn.btn-github.btn-neutral:active {
  color: #171a1d;
}

.btn.btn-youtube {
  background-color: #e52d27;
  color: #fff;
}

.btn.btn-youtube:focus,
.btn.btn-youtube:hover {
  background-color: #d41f1a;
  color: #fff;
}

.btn.btn-youtube:active,
.btn.btn-youtube:focus,
.btn.btn-youtube:active:focus {
  box-shadow: none;
}

.btn.btn-youtube.btn-simple {
  color: #d41f1a;
  background-color: transparent;
  background-image: none !important;
  box-shadow: none;
  border: none;
}

.btn.btn-youtube.btn-simple:hover,
.btn.btn-youtube.btn-simple:focus,
.btn.btn-youtube.btn-simple:hover:focus,
.btn.btn-youtube.btn-simple:active,
.btn.btn-youtube.btn-simple:hover:focus:active {
  color: #d41f1a;
  background: transparent !important;
  box-shadow: none !important;
}

.btn.btn-youtube.btn-neutral {
  color: #e52d27;
  background-color: #fff;
}

.btn.btn-youtube.btn-neutral:hover,
.btn.btn-youtube.btn-neutral:focus,
.btn.btn-youtube.btn-neutral:active {
  color: #d41f1a;
}

.btn.btn-instagram {
  background-color: #125688;
  color: #fff;
}

.btn.btn-instagram:focus,
.btn.btn-instagram:hover {
  background-color: #0e456d;
  color: #fff;
}

.btn.btn-instagram:active,
.btn.btn-instagram:focus,
.btn.btn-instagram:active:focus {
  box-shadow: none;
}

.btn.btn-instagram.btn-simple {
  color: #0e456d;
  background-color: transparent;
  background-image: none !important;
  box-shadow: none;
  border: none;
}

.btn.btn-instagram.btn-simple:hover,
.btn.btn-instagram.btn-simple:focus,
.btn.btn-instagram.btn-simple:hover:focus,
.btn.btn-instagram.btn-simple:active,
.btn.btn-instagram.btn-simple:hover:focus:active {
  color: #0e456d;
  background: transparent !important;
  box-shadow: none !important;
}

.btn.btn-instagram.btn-neutral {
  color: #125688;
  background-color: #fff;
}

.btn.btn-instagram.btn-neutral:hover,
.btn.btn-instagram.btn-neutral:focus,
.btn.btn-instagram.btn-neutral:active {
  color: #0e456d;
}

.btn.btn-reddit {
  background-color: #ff4500;
  color: #fff;
}

.btn.btn-reddit:focus,
.btn.btn-reddit:hover {
  background-color: #e03d00;
  color: #fff;
}

.btn.btn-reddit:active,
.btn.btn-reddit:focus,
.btn.btn-reddit:active:focus {
  box-shadow: none;
}

.btn.btn-reddit.btn-simple {
  color: #e03d00;
  background-color: transparent;
  background-image: none !important;
  box-shadow: none;
  border: none;
}

.btn.btn-reddit.btn-simple:hover,
.btn.btn-reddit.btn-simple:focus,
.btn.btn-reddit.btn-simple:hover:focus,
.btn.btn-reddit.btn-simple:active,
.btn.btn-reddit.btn-simple:hover:focus:active {
  color: #e03d00;
  background: transparent !important;
  box-shadow: none !important;
}

.btn.btn-reddit.btn-neutral {
  color: #ff4500;
  background-color: #fff;
}

.btn.btn-reddit.btn-neutral:hover,
.btn.btn-reddit.btn-neutral:focus,
.btn.btn-reddit.btn-neutral:active {
  color: #e03d00;
}

.btn.btn-tumblr {
  background-color: #35465c;
  color: #fff;
}

.btn.btn-tumblr:focus,
.btn.btn-tumblr:hover {
  background-color: #2a3749;
  color: #fff;
}

.btn.btn-tumblr:active,
.btn.btn-tumblr:focus,
.btn.btn-tumblr:active:focus {
  box-shadow: none;
}

.btn.btn-tumblr.btn-simple {
  color: #2a3749;
  background-color: transparent;
  background-image: none !important;
  box-shadow: none;
  border: none;
}

.btn.btn-tumblr.btn-simple:hover,
.btn.btn-tumblr.btn-simple:focus,
.btn.btn-tumblr.btn-simple:hover:focus,
.btn.btn-tumblr.btn-simple:active,
.btn.btn-tumblr.btn-simple:hover:focus:active {
  color: #2a3749;
  background: transparent !important;
  box-shadow: none !important;
}

.btn.btn-tumblr.btn-neutral {
  color: #35465c;
  background-color: #fff;
}

.btn.btn-tumblr.btn-neutral:hover,
.btn.btn-tumblr.btn-neutral:focus,
.btn.btn-tumblr.btn-neutral:active {
  color: #2a3749;
}

.btn.btn-behance {
  background-color: #1769ff;
  color: #fff;
}

.btn.btn-behance:focus,
.btn.btn-behance:hover {
  background-color: #0057f7;
  color: #fff;
}

.btn.btn-behance:active,
.btn.btn-behance:focus,
.btn.btn-behance:active:focus {
  box-shadow: none;
}

.btn.btn-behance.btn-simple {
  color: #0057f7;
  background-color: transparent;
  background-image: none !important;
  box-shadow: none;
  border: none;
}

.btn.btn-behance.btn-simple:hover,
.btn.btn-behance.btn-simple:focus,
.btn.btn-behance.btn-simple:hover:focus,
.btn.btn-behance.btn-simple:active,
.btn.btn-behance.btn-simple:hover:focus:active {
  color: #0057f7;
  background: transparent !important;
  box-shadow: none !important;
}

.btn.btn-behance.btn-neutral {
  color: #1769ff;
  background-color: #fff;
}

.btn.btn-behance.btn-neutral:hover,
.btn.btn-behance.btn-neutral:focus,
.btn.btn-behance.btn-neutral:active {
  color: #0057f7;
}

.btn.btn-vimeo {
  background-color: #1AB7EA;
  color: #fff;
}

.btn.btn-vimeo:focus,
.btn.btn-vimeo:hover {
  background-color: #13a3d2;
  color: #fff;
}

.btn.btn-vimeo:active,
.btn.btn-vimeo:focus,
.btn.btn-vimeo:active:focus {
  box-shadow: none;
}

.btn.btn-vimeo.btn-simple {
  color: #13a3d2;
  background-color: transparent;
  background-image: none !important;
  box-shadow: none;
  border: none;
}

.btn.btn-vimeo.btn-simple:hover,
.btn.btn-vimeo.btn-simple:focus,
.btn.btn-vimeo.btn-simple:hover:focus,
.btn.btn-vimeo.btn-simple:active,
.btn.btn-vimeo.btn-simple:hover:focus:active {
  color: #13a3d2;
  background: transparent !important;
  box-shadow: none !important;
}

.btn.btn-vimeo.btn-neutral {
  color: #1AB7EA;
  background-color: #fff;
}

.btn.btn-vimeo.btn-neutral:hover,
.btn.btn-vimeo.btn-neutral:focus,
.btn.btn-vimeo.btn-neutral:active {
  color: #13a3d2;
}

.btn.btn-slack {
  background-color: #3aaf85;
  color: #fff;
}

.btn.btn-slack:focus,
.btn.btn-slack:hover {
  background-color: #329874;
  color: #fff;
}

.btn.btn-slack:active,
.btn.btn-slack:focus,
.btn.btn-slack:active:focus {
  box-shadow: none;
}

.btn.btn-slack.btn-simple {
  color: #329874;
  background-color: transparent;
  background-image: none !important;
  box-shadow: none;
  border: none;
}

.btn.btn-slack.btn-simple:hover,
.btn.btn-slack.btn-simple:focus,
.btn.btn-slack.btn-simple:hover:focus,
.btn.btn-slack.btn-simple:active,
.btn.btn-slack.btn-simple:hover:focus:active {
  color: #329874;
  background: transparent !important;
  box-shadow: none !important;
}

.btn.btn-slack.btn-neutral {
  color: #3aaf85;
  background-color: #fff;
}

.btn.btn-slack.btn-neutral:hover,
.btn.btn-slack.btn-neutral:focus,
.btn.btn-slack.btn-neutral:active {
  color: #329874;
}

.table thead th {
  padding: 0.75rem 1.5rem;
  text-transform: capitalize;
  letter-spacing: 0px;
  border-bottom: 1px solid #f0f2f5;
}

.table th {
  font-weight: 600;
}

.table td .progress {
  height: 3px;
  width: 120px;
  margin: 0;
}

.table td .progress .progress-bar {
  height: 3px;
}

.table td,
.table th {
  white-space: nowrap;
}

.table.align-items-center td,
.table.align-items-center th {
  vertical-align: middle;
}

.table tbody tr:last-child td {
  border-width: 0;
}

.table> :not(:last-child)> :last-child>* {
  border-bottom-color: #f0f2f5;
}

.table> :not(:first-child) {
  border-top: 1px solid currentColor;
}

.timeline {
  position: relative;
}

.timeline:before {
  content: '';
  position: absolute;
  top: 0;
  left: 1rem;
  height: 100%;
  border-right: 2px solid #e5e5e5;
}

.timeline.timeline-dark:before {
  border-right-color: #4a4a4a;
}

.timeline-block {
  position: relative;
}

.timeline-block:after {
  content: "";
  display: table;
  clear: both;
}

.timeline-block:first-child {
  margin-top: 0;
}

.timeline-block:last-child {
  margin-bottom: 0;
}

.timeline-step {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  left: 0;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #fff;
  text-align: center;
  transform: translateX(-50%);
  font-size: 1rem;
  font-weight: 600;
  z-index: 1;
}

.timeline-step svg,
.timeline-step i {
  line-height: 1.4;
}

.timeline-content {
  position: relative;
  margin-left: 45px;
  padding-top: 0.35rem;
  position: relative;
  top: -6px;
}

.timeline-content:after {
  content: "";
  display: table;
  clear: both;
}

@media (min-width: 992px) {
  .timeline:before {
    left: 50%;
    margin-left: -1px;
  }

  .timeline-step {
    left: 50%;
  }

  .timeline-content {
    width: 38%;
  }

  .timeline-block:nth-child(even) .timeline-content {
    float: right;
  }
}

.timeline-one-side:before {
  left: 1rem;
}

.timeline-one-side .timeline-step {
  left: 1rem;
}

.timeline-one-side .timeline-content {
  width: auto;
}

@media (min-width: 992px) {
  .timeline-one-side .timeline-content {
    max-width: 30rem;
  }
}

.timeline-one-side .timeline-block:nth-child(even) .timeline-content {
  float: none;
}

.tilt {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.tilt .up {
  -webkit-transform: translateZ(50px) scale(0.7);
  transform: translateZ(50px) scale(0.7) !important;
  transition: all 0.5s;
}

.bs-tooltip-auto[x-placement^=right] .tooltip-arrow,
.bs-tooltip-right .tooltip-arrow {
  left: 1px;
}

.bs-tooltip-auto[x-placement^=left] .tooltip-arrow,
.bs-tooltip-left .tooltip-arrow {
  right: 1px;
}

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-weight: 400;
  line-height: 1.6;
}

h1,
.h1,
.h1 {
  font-size: 3rem;
  line-height: 1.25;
  letter-spacing: 0;
}

@media (max-width: 575.98px) {

  h1,
  .h1,
  .h1 {
    font-size: calc(1.425rem + 2.1vw);
  }
}

h2,
.h2,
.h2 {
  font-size: 2.25rem;
  line-height: 1.3;
  letter-spacing: 0.05rem;
}

@media (max-width: 575.98px) {

  h2,
  .h2,
  .h2 {
    font-size: calc(1.35rem + 1.2vw);
  }
}

h3,
.h3,
.h3 {
  font-size: 1.875rem;
  line-height: 1.375;
}

@media (max-width: 575.98px) {

  h3,
  .h3,
  .h3 {
    font-size: calc(1.3125rem + 0.75vw);
  }
}

h4,
.h4,
.h4 {
  font-size: 1.5rem;
  line-height: 1.375;
}

@media (max-width: 575.98px) {

  h4,
  .h4,
  .h4 {
    font-size: calc(1.275rem + 0.3vw);
  }
}

h5,
.h5,
.h5 {
  font-size: 1.25rem;
  line-height: 1.375;
}

@media (max-width: 575.98px) {

  h5,
  .h5,
  .h5 {
    font-size: 1.25rem;
  }
}

h6,
.h6,
.h6 {
  font-size: 1rem;
  line-height: 1.625;
}

p,
.p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
}

.lead {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.625;
}

h1,
.h1,
.h1,
h2,
.h2,
.h2,
h3,
.h3,
.h3 {
  font-weight: 600;
  font-family: var(--bs-font-sans-serif);
}

h4,
.h4,
.h4,
h5,
.h5,
.h5,
h6,
.h6,
.h6 {
  font-weight: 600;
}

h1,
.h1,
.h1,
h2,
.h2,
.h2,
h3,
.h3,
.h3,
h4,
.h4,
.h4 {
  letter-spacing: -0.05rem;
}

a {
  letter-spacing: 0rem;
  color: #344767;
}

.text-sm {
  line-height: 1.5;
}

.text-xs {
  line-height: 1.25;
}

p,
.p {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
}

.text-lg {
  font-size: 1.125rem !important;
}

.text-md {
  font-size: 1rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
}

.text-xs {
  font-size: 0.75rem !important;
}

.text-xxs {
  font-size: 0.65rem !important;
}

p {
  line-height: 1.625;
  font-weight: 300;
}

.text-sans-serif {
  font-family: "Roboto", Helvetica, Arial, sans-serif !important;
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 600 !important;
}

.font-weight-bolder {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-gradient {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  z-index: 1;
}

.text-gradient.text-primary {
  background-image: linear-gradient(195deg, #EC407A, #D81B60);
}

.text-gradient.text-info {
  background-image: linear-gradient(195deg, #49a3f1, #1A73E8);
}

.text-gradient.text-success {
  background-image: linear-gradient(195deg, #66BB6A, #43A047);
}

.text-gradient.text-warning {
  background-image: linear-gradient(195deg, #FFA726, #FB8C00);
}

.text-gradient.text-danger {
  background-image: linear-gradient(195deg, #EF5350, #E53935);
}

.text-gradient.text-dark {
  background-image: linear-gradient(195deg, #42424a, #191919);
}

.blockquote {
  border-left: 3px solid #6c757d;
}

.blockquote>span {
  font-style: italic;
}

.text-muted {
  color: #7b809a !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.letter-wider {
  letter-spacing: 0.05rem;
}

.letter-normal {
  letter-spacing: 0rem;
}

.letter-tighter {
  letter-spacing: -0.05rem;
}

.text-lighter {
  font-weight: lighter;
}

.text-light {
  font-weight: 300;
}

.text-normal {
  font-weight: 400;
}

.text-bold {
  font-weight: 600;
}

.text-bolder {
  font-weight: 700;
}

.text-2xl {
  font-size: 1.5rem;
}

.text-3xl {
  font-size: 1.875rem;
}

.text-4xl {
  font-size: 2rem;
}

.text-5xl {
  font-size: 2.25rem;
}

.text-6xl {
  font-size: 3rem;
}

.text-7xl {
  font-size: 3.75rem;
}

.text-8xl {
  font-size: 4rem;
}

.text-9xl {
  font-size: 5rem;
}

.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 0.75rem;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  -webkit-box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  transform: scale(0.95) !important;
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
  transform: scale(1) !important;
}

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px;
}

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.arrowRight:before,
.flatpickr-calendar.rightMost:after,
.flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar.arrowCenter:before,
.flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #fff;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: #344767;
  fill: rgba(0, 0, 0, 0.8);
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
      /*rtl:begin:ignore*/
  /*
      */
  left: 0;
  /*
      /*rtl:end:ignore*/
  /*
      */
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
      /*rtl:begin:ignore*/
  /*
      */
  right: 0;
  /*
      /*rtl:end:ignore*/
  /*
      */
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit;
}

.numInputWrapper {
  position: relative;
  height: auto;
}

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57, 57, 57, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5);
}

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}

.numInputWrapper:hover span {
  opacity: 1;
}

