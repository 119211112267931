
.row {
    margin: 0;
  }
  
  .navbar{
    padding: 0px 0px;
  }
  .container {
    padding: 0px 0;
  }

  .borda:before { 
    content:'';

    border-bottom: 1px solid #F3F4F6;
    width: 19%;
    height: 20px;
    margin-top: 40px;
    position: absolute;
}





.meusProjetos{
    font-family: "Archivo", serif;
    font-size: 32px;
    font-weight: bold;
    color: #171A1F;
}

.tituloCards {
    font-family: "Inter", serif;
    font-weight: 400;
    font-size: 14px;
    text-decoration: none;
    color: #574A69;
}
.numerosCards {
    font-family: "Archivo", serif;
    font-weight: 600;
    font-size: 32px;
    margin-top: 20px;
    text-decoration: none;
    color: #574A69;
}

.emAndamento{
    font-family: "Inter", serif;
    font-weight: 400;
    font-size: 12px;
    text-decoration: none;
    color: #98690C;
    background-color: #FEF9EE;
    padding: 5px;
    border-radius: 16px;
    margin-left: 6px;
}

.concluido{
    font-family: "Inter", serif;
    font-weight: 400;
    font-size: 12px;
    text-decoration: none;
    color: #117B34;
    background-color: #EEFDF3;
    padding: 5px;
    border-radius: 16px;
    margin-left: 6px;
}

.seletores{
    font-family: "Inter", serif;
    font-weight: 400;
    font-size: 14px;
    text-decoration: none;
    color: #565D6D;
    margin-left: 18px;
}

.principalTitle{
    font-family: "Convergence", serif;
    font-weight: 400;
    font-size: 20px;
    color: #171A1F;
}

.red{
    font-family: "Inter", serif;
    font-weight: 400;
    font-size: 10px;
    color: #DE3B40;

}

.textobotoes{
    font-family: "Inter", serif;
    font-weight: 400;
    font-size: 14px;
    color: #574A69;

}

.descricoes {
    font-family: "Inter", serif;
    font-weight: 400;
    font-size: 16px;
    color: #424856;
} 